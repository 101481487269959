import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  ArrowBackIos,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { Button as MuiButton, styled } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Org } from '../../../../types';
import { Equipment } from '../../../../types/Resource';

import {
  getEquipmentGroups,
  getGroupOfEquipment,
  getProjectDaspowers,
  setEquipmentToAGroup,
} from '../../../../apis/DaspowerApi';
import { getProjectEquipments } from '../../../../apis/ProjectApi';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setDaspowersOnEquipment } from '../../../../slices/pages/project-setting/equipmentManagementPageSlice';

import EquipmentListOfGroup from './EquipmentListOfGroup';
import EquipmentPermissionList from './EquipmentPermissionList';

const Container = styled('div', { label: 'WorkerManagementPage-Container' })`
  display: flex;
  flex-direction: column;
`;
const Header = styled('div')`
  height: 32px;
  margin-bottom: 10px;
`;

const ContainerMain = styled('div')`
  display: flex;
  height: calc(100vh - 129px);
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ContainerButton = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled(MuiButton)`
  color: ${({ theme }) => theme.color.box_bbg};
  padding: 0%;
  margin: 0;
  min-width: 32px;
  height: 32px;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
  &.Mui-disabled {
    color: rgba(255, 255, 255, 0.1);
  }
`;

const EditEquipmentManagement: React.VFC = () => {
  const {
    mutateAsync: mutateSetEquipmentToAGroup,
    isPending: isLoadingSetEquipment,
  } = useMutation({
    mutationFn: setEquipmentToAGroup,
  });

  const { projectId } = useParams();
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const { selectedGroup } = useAppSelector(
    (store) => store.equipmentManagement,
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isEnableRemoveEquipments, setIsEnableRemoveEquipments] =
    useState(true);
  const [isEnableSetEquipments, setIsEnableSetEquipments] = useState(true);
  const [listEquipmentOfAGroupWillAssign, setListEquipmentOfAGroupWillAssign] =
    useState<Array<string>>([]);
  const [listEquipmentProjectWillAssign, setListEquipmentProjectWillAssign] =
    useState<Array<string>>([]);

  const dispatch = useAppDispatch();

  const {
    data: equipmentOfProject,
    refetch: refetchEquipmentOfProject,
    isLoading: isLoadingEquipmentOfProject,
  } = useQuery({
    queryKey: ['get-equipment-of-project', projectId],
    queryFn: async () => getProjectEquipments({ projectId: projectId ?? '' }),
    enabled: !!projectId,
  });

  const { data: equipmentGroupAProject } = useQuery({
    queryKey: ['get-equipment-group'],
    queryFn: async () => {
      const res = await getEquipmentGroups(projectId as string);
      return res.data.data;
    },
    enabled: !!projectId,
    initialData: [],
  });

  const {
    data: equipmentsOfAGroup,
    refetch: refetchEquipmentsOfAGroup,
    isLoading: isLoadingEquipmentOfAGroup,
  } = useQuery({
    queryKey: ['get-equipment-of-a-group', projectId],
    queryFn: async () =>
      getGroupOfEquipment({
        projectId: projectId as string,
        groupId: selectedGroup?.id ?? '',
      }),
    enabled: !!selectedGroup,
  });

  const { data: daspowers } = useQuery({
    queryKey: ['get-daspower-project', projectId],
    queryFn: async () => getProjectDaspowers({ projectId: projectId ?? '' }),
    enabled: !!projectId,
  });

  const equipments: Array<Equipment & { orgDetail: Org }> = useMemo(() => {
    const EmptyOrg: Org = {
      id: '',
      ownerId: '',
      name: '',
      country: '',
      taxId: '',
      color: '',
      displayName: '',
      type: 'customer',
      imageURL: null,
      status: 'ACTIVE',
    };
    if (equipmentsOfAGroup && equipmentsOfAGroup.data.data.length > 0)
      return equipmentsOfAGroup?.data.data.map((equipment) => ({
        ...equipment,
        orgDetail: orgMap[equipment.orgId] ?? EmptyOrg,
      }));
    return [];
  }, [equipmentsOfAGroup, orgMap]);

  const equipmentsProject: Array<Equipment & { orgDetail: Org }> =
    useMemo(() => {
      const EmptyOrg: Org = {
        id: '',
        ownerId: '',
        name: '',
        country: '',
        taxId: '',
        color: '',
        displayName: '',
        type: 'customer',
        imageURL: null,
        status: 'ACTIVE',
      };
      if (
        equipmentOfProject &&
        equipmentOfProject.data.data.length > 0 &&
        equipmentsOfAGroup
      ) {
        return equipmentOfProject.data.data
          .filter((wp) =>
            equipmentsOfAGroup?.data.data.every((wg) => wg.id !== wp.id),
          )
          .map((equipment) => ({
            ...equipment,
            orgDetail: orgMap[equipment.orgId] ?? EmptyOrg,
          }));
      }
      return [];
    }, [equipmentOfProject, equipmentsOfAGroup, orgMap]);

  const handleSubmit = async () => {
    try {
      await mutateSetEquipmentToAGroup(
        {
          projectId: projectId as string,
          groupId: selectedGroup.id,
          equipmentIds: listEquipmentOfAGroupWillAssign.concat(
            listEquipmentProjectWillAssign,
          ),
        },
        {
          onSuccess: () => {
            refetchEquipmentsOfAGroup();
            refetchEquipmentOfProject();
            setIsEnableRemoveEquipments(true);
            setIsEnableSetEquipments(true);
            setListEquipmentOfAGroupWillAssign([]);
            setListEquipmentProjectWillAssign([]);
          },
        },
      );
    } catch (err) {
      const error = err as Error;
      alert(error.message);
    }
  };

  useEffect(() => {
    if (daspowers) {
      dispatch(setDaspowersOnEquipment(daspowers?.data.data));
    }
  }, [daspowers]);

  useEffect(() => {
    if (listEquipmentOfAGroupWillAssign.length < equipments.length) {
      setIsEnableRemoveEquipments(false);
    } else {
      setIsEnableRemoveEquipments(true);
    }
  }, [listEquipmentOfAGroupWillAssign, equipments]);

  useEffect(() => {
    if (listEquipmentProjectWillAssign.length > 0) {
      setIsEnableSetEquipments(false);
    } else {
      setIsEnableSetEquipments(true);
    }
  }, [listEquipmentProjectWillAssign]);

  return (
    <Container>
      <Header>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': {
              background: 'transparent',
            },
          }}
          color="secondary"
          onClick={() =>
            navigate(`/project-setting/${projectId}/equipment-group-management`)
          }
          startIcon={<ArrowBackIos />}
          data-cy="btn-end-edit-equipment-list"
        >
          {t('end-editing')}
        </DialogButton>
      </Header>
      <ContainerMain>
        <EquipmentListOfGroup
          selectedId={(wg) => setListEquipmentOfAGroupWillAssign(wg)}
          data={equipments}
          isLoadingEquipmentOfAGroup={isLoadingEquipmentOfAGroup}
        />
        <ContainerButton>
          <Button
            onClick={handleSubmit}
            disabled={isEnableRemoveEquipments}
            data-cy="btn-remove-equipment-to-group"
          >
            <KeyboardDoubleArrowRight />
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isEnableSetEquipments}
            data-cy="btn-add-equipment-to-group"
          >
            <KeyboardDoubleArrowLeft />
          </Button>
        </ContainerButton>
        <EquipmentPermissionList
          data={equipmentsProject}
          equipmentGroupProject={equipmentGroupAProject ?? []}
          selectedId={(wp) => setListEquipmentProjectWillAssign(wp)}
          isLoadingSetEquipment={isLoadingSetEquipment}
          isLoadingEquipmentOfProject={isLoadingEquipmentOfProject}
        />
      </ContainerMain>
      <Outlet />
    </Container>
  );
};

export default EditEquipmentManagement;
