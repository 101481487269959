import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  ArrowBackIos,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { Button as MuiButton, styled } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { Group, PagedResponse, ResourceType } from '../../../types';
import { Resource, Worker } from '../../../types/Resource';

import {
  getGroupOfWorkers,
  getWorkerGroups,
  setGroupWorkers,
} from '../../../apis/DasloopApi';
import {
  getEquipmentGroups,
  getGroupOfEquipment,
} from '../../../apis/DaspowerApi';
import {
  getGroupOfPlant,
  getPlantGroups,
  getProjectPlants,
  setPlantToAGroup,
} from '../../../apis/DastrackApi';
import {
  getGroupOfVehicle,
  getProjectVehicles,
  getVehicleGroups,
} from '../../../apis/DastrackVApi';
import {
  getGroupOfPipe,
  getPipeGroups,
  getProjectPipes,
} from '../../../apis/DaswaterApi';
import { getProjectEquipments } from '../../../apis/ProjectApi';
import {
  getGroupOfResources,
  getProjectGroups,
  getProjectOfResources,
  setGroupResources,
} from '../../../apis/ResourceApi';
import { useAppSelector } from '../../../hooks';

import OneGroupOfResourceList from './OneGroupOfResourceList';
import ResourcePermissionList from './ResourcePermissionList';

const Container = styled('div', { label: 'WorkerManagementPage-Container' })`
  display: flex;
  flex-direction: column;
`;
const Header = styled('div')`
  height: 32px;
  margin-bottom: 10px;
`;

const ContainerMain = styled('div')`
  display: flex;
  height: calc(100vh - 129px);
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ContainerButton = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled(MuiButton)`
  color: ${({ theme }) => theme.color.box_bbg};
  padding: 0%;
  margin: 0;
  min-width: 32px;
  height: 32px;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
  &.Mui-disabled {
    color: rgba(255, 255, 255, 0.1);
  }
`;

const EditGroupOfResource: React.FC = () => {
  const workers = useAppSelector(
    (store) => Object.values(store.projects.resource.workerMap) as Worker[],
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const resourceType = location.pathname
    .split('/')?.[3]
    .replace('-group-management', '') as ResourceType;
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId');
  const setWorkerGroup = useMutation({
    mutationFn: setGroupWorkers,
  });
  const setGroups = useMutation({
    mutationFn: setGroupResources,
  });
  const setPlantGroups = useMutation({
    mutationFn: setPlantToAGroup,
  });
  const [removeResources, setRemoveResources] = useState<Array<string>>([]);
  const [addResources, setAddResources] = useState<Array<string>>([]);

  const {
    data: groupOfResoureces,
    refetch: refetchGroupOfResoureces,
    isFetching: isLoadingGroupOfResoureces,
  } = useQuery({
    queryKey: ['get-group-of-resources', projectId, groupId, resourceType],
    queryFn: async () => {
      let queryFn:
        | ((data: any) => Promise<AxiosResponse<PagedResponse<Resource>, any>>)
        | undefined;
      switch (resourceType) {
        case 'worker':
          queryFn = getGroupOfWorkers;
          break;
        case 'plant':
        case 'crane':
          queryFn = getGroupOfPlant;
          break;
        case 'equipment':
          queryFn = getGroupOfEquipment;
          break;
        case 'vehicle':
          queryFn = getGroupOfVehicle;
          break;
        case 'pipe':
          queryFn = getGroupOfPipe;
          break;
        case 'environment':
          queryFn = getGroupOfResources;
          break;
        default:
          queryFn = undefined;
      }

      let resources: Resource[] = [];

      let plantType;
      if (resourceType === 'crane') {
        plantType = 'crane';
      } else if (resourceType === 'plant') {
        plantType = 'plant';
      }

      const load = async (nextCursor?: string) => {
        if (queryFn) {
          const res = await queryFn({
            projectId: projectId as string,
            resourceType,
            groupId: groupId as string,
            params: {
              nextCursor,
              plantType,
            },
          });

          resources = resources.concat(res.data.data);

          if (res.data.paging.nextCursor) {
            await load(res.data.paging.nextCursor);
          }
        }
      };

      await load();

      return resources;
    },
    enabled: !!projectId && !!groupId,
    initialData: [],
  });

  const { data: projectOfResources, isFetching: isLoadingProjectOfResources } =
    useQuery({
      queryKey: ['get-project-of-resources', projectId],
      queryFn: async () => {
        let queryFn:
          | ((
              data: any,
            ) => Promise<AxiosResponse<PagedResponse<Resource>, any>>)
          | undefined;

        switch (resourceType) {
          case 'plant':
          case 'crane':
            queryFn = getProjectPlants;
            break;
          case 'equipment':
            queryFn = getProjectEquipments;
            break;
          case 'vehicle':
            queryFn = getProjectVehicles;
            break;
          case 'pipe':
            queryFn = getProjectPipes;
            break;
          case 'environment':
            queryFn = getProjectOfResources;
            break;
          default:
            return [];
        }

        let resources: Resource[] = [];
        let plantType;
        if (resourceType === 'crane') {
          plantType = 'crane';
        } else if (resourceType === 'plant') {
          plantType = 'plant';
        }

        const load = async (nextCursor?: string) => {
          if (queryFn) {
            const res = await queryFn({
              projectId: projectId as string,
              resourceType,
              params: {
                nextCursor,
                plantType,
              },
            });

            resources = resources.concat(res.data.data);

            if (res.data.paging.nextCursor) {
              await load(res.data.paging.nextCursor);
            }
          }
        };

        await load();

        return resources;
      },
      enabled: !!groupOfResoureces,
      initialData: [],
    });

  const { data: selectedGroup } = useQuery<Group>({
    queryKey: ['get-resource-group', resourceType, projectId, groupId],
    queryFn: async () => {
      let queryFn;
      if (resourceType === 'worker') {
        queryFn = getWorkerGroups;
      } else if (resourceType === 'plant' || resourceType === 'crane') {
        queryFn = getPlantGroups;
      } else if (resourceType === 'equipment') {
        queryFn = getEquipmentGroups;
      } else if (resourceType === 'vehicle') {
        queryFn = getVehicleGroups;
      } else if (resourceType === 'pipe') {
        queryFn = getPipeGroups;
      } else if (resourceType === 'environment') {
        queryFn = getProjectGroups;
      }

      if (queryFn) {
        let res;
        if (resourceType !== 'environment') {
          res = await queryFn(projectId as string);
        } else {
          res = await queryFn({ projectId, resourceType });
        }
        if (res?.data?.data) {
          for (let i = 0; i < res.data.data.length; i++) {
            if (groupId === res.data.data[i].id) {
              return res.data.data[i];
            }
          }
          return res.data.data[0];
        }
      }
    },
    enabled: !!projectId && !!groupId,
    refetchOnWindowFocus: false,
  });

  const filteredProjectOfResources: Array<Resource> = useMemo(() => {
    let resources: Resource[] = [];
    const groupOfResourecesMap = groupOfResoureces?.reduce<{
      [id: string]: Resource | undefined;
    }>((prev, curr) => {
      prev[curr.id] = curr;
      return prev;
    }, {});

    if (resourceType === 'worker') {
      resources = workers;
    } else {
      resources = projectOfResources;
    }

    return resources.filter((resource) => {
      return !groupOfResourecesMap[resource.id];
    });
  }, [resourceType, projectOfResources, groupOfResoureces, workers]);

  const handleAddResource = () => {
    if (groupId) {
      try {
        if (resourceType === 'worker') {
          setWorkerGroup.mutate(
            {
              projectId: projectId as string,
              groupId,
              workerIds: groupOfResoureces
                .map((resource) => resource.id)
                .concat(addResources),
            },
            {
              onSuccess: () => {
                refetchGroupOfResoureces();
                setAddResources([]);
              },
            },
          );
        } else if (resourceType === 'environment') {
          setGroups.mutate(
            {
              projectId: projectId as string,
              resourceType,
              groupId,
              payload: {
                resourceIds: groupOfResoureces
                  .map((resource) => resource.id)
                  .concat(addResources),
              },
            },
            {
              onSuccess: () => {
                refetchGroupOfResoureces();
                setAddResources([]);
              },
            },
          );
        } else if (resourceType === 'crane') {
          setPlantGroups.mutate(
            {
              projectId: projectId as string,
              groupId,
              plantIds: groupOfResoureces
                .map((resource) => resource.id)
                .concat(addResources),
            },
            {
              onSuccess: () => {
                refetchGroupOfResoureces();
                setAddResources([]);
              },
            },
          );
        }
      } catch (err) {
        const error = err as Error;
        alert(error.message);
      }
    }
  };

  const handleRemove = () => {
    if (groupId) {
      const groupResoureceIdMap = groupOfResoureces.reduce<{
        [id: string]: boolean | undefined;
      }>((prev, curr) => {
        prev[curr.id] = true;
        return prev;
      }, {});

      removeResources.forEach((resourceId) => {
        groupResoureceIdMap[resourceId] = false;
      });

      if (resourceType === 'worker') {
        setWorkerGroup.mutate(
          {
            projectId: projectId as string,
            groupId,
            workerIds: Object.entries(groupResoureceIdMap)
              .filter(([, bool]) => bool)
              .map(([id]) => id),
          },
          {
            onSuccess: () => {
              refetchGroupOfResoureces();
              setRemoveResources([]);
            },
            onError: (err) => {
              const error = err as Error;
              alert(error.message);
            },
          },
        );
      } else if (resourceType === 'environment') {
        setGroups.mutate(
          {
            projectId: projectId as string,
            resourceType,
            groupId,
            payload: {
              resourceIds: Object.entries(groupResoureceIdMap)
                .filter(([, bool]) => bool)
                .map(([id]) => id),
            },
          },
          {
            onSuccess: () => {
              refetchGroupOfResoureces();
              setRemoveResources([]);
            },
            onError: (err) => {
              const error = err as Error;
              alert(error.message);
            },
          },
        );
      } else if (resourceType === 'crane') {
        setPlantGroups.mutate(
          {
            projectId: projectId as string,
            groupId,
            plantIds: Object.entries(groupResoureceIdMap)
              .filter(([, bool]) => bool)
              .map(([id]) => id),
          },
          {
            onSuccess: () => {
              refetchGroupOfResoureces();
              setAddResources([]);
            },
          },
        );
      }
    }
  };

  return (
    <Container>
      <Header>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': {
              background: 'transparent',
            },
          }}
          color="secondary"
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIos />}
          data-cy={`btn-end-edit-${resourceType}-list`}
        >
          {t('end-editing')}
        </DialogButton>
      </Header>
      <ContainerMain>
        <OneGroupOfResourceList
          resourceType={resourceType}
          resources={groupOfResoureces}
          groupName={selectedGroup?.name}
          isLoadingGroupResources={isLoadingGroupOfResoureces}
          onChange={(ids) => setRemoveResources(ids)}
        />
        <ContainerButton>
          <Button
            onClick={handleRemove}
            disabled={
              setWorkerGroup.isPending ||
              setGroups.isPending ||
              removeResources.length === 0
            }
            data-cy={'btn-remove-resource-to-group'}
          >
            <KeyboardDoubleArrowRight />
          </Button>
          <Button
            onClick={handleAddResource}
            disabled={
              setWorkerGroup.isPending ||
              setGroups.isPending ||
              addResources.length === 0
            }
            data-cy={'btn-add-resource-to-group'}
          >
            <KeyboardDoubleArrowLeft />
          </Button>
        </ContainerButton>
        <ResourcePermissionList
          resourceType={resourceType}
          resources={filteredProjectOfResources}
          onChange={(ids) => setAddResources(ids)}
          isLoadingProjectResource={isLoadingProjectOfResources}
        />
      </ContainerMain>
    </Container>
  );
};

export default EditGroupOfResource;
