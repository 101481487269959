import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  Contact,
  DasIdWillAssign,
  StatusType,
  UpdateStatus,
  VehicleAssign,
  VehicleGroup,
  VehicleWithOrg,
} from '../../../../types';
import { DastrackV } from '../../../../types/Device';
import { ResourceNavigator } from '../../../../types/Resource';

import {
  bindDastrackVToVehicle,
  setVehicleToGroup,
  unbindDastrackVFromVehicle,
  updateVehicle,
  uploadVehicleAvatar,
} from '../../../../apis/DastrackVApi';
import {
  bindProjectContact,
  unBindProjectContact,
} from '../../../../apis/ProjectApi';
import { useAppSelector } from '../../../../hooks';
import useFilteredGroups from '../../../../hooks/useFilteredGroups';
import useHasPolicy from '../../../../hooks/useHasPolicy';

import CheckListItem from '../../../../components/CheckListItem';
import ConfirmButton from '../../../../components/ConfirmButton';
import ManagementDialog from '../../../../components/Dialog/ManagementDialog';
import ManagementNoPermissionContent from '../../../../components/ManagementNoPermissionContent';

import CompleteButtonSvgIcon from '../../../../assets/SvgIcon/CompleteButtonSvgIcon';
import LoadingButtonSvgIcon from '../../../../assets/SvgIcon/LoadingButtonSvgIcon';
import SaveSvgIcon from '../../../../assets/SvgIcon/SaveSvgIcon';
import mainTheme from '../../../../theme';
import { checkIsArrayEqual } from '../../../../utils/checkArrayEqual';
import ContactContent from '../../management/edit-content/ContactEdit';
import GroupEdit from '../../management/edit-content/GroupEdit';
import PathEdit from '../../management/edit-content/PathEdit';
import {
  checkIdEqual,
  checkIsAvatarUpdated,
  checkIsDasIdEqual,
  checkResourceHasContact,
  hasAssignedDevice,
} from '../../management/validation/management-validation';
import {
  checkIsFormVehicleEdited,
  checkIsPathSelected,
  checkIsVehicleInformationValid,
} from '../../management/validation/vehicle-validation';
import DeviceVehicle from '../Operation/DeviceVehicle';
import VehicleInformation from '../Operation/VehicleInformation';

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  display: flex;
  flex: 1;
`;

const ContentWrapper = styled('div')`
  width: 340px;
  border-radius: 4px;
  display: flex;
  flex: 1;
`;

const DialogActions = styled(MuiDialogActions)`
  display: flex;
  padding: 24px;
  height: 80px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  width: 280px;
  margin-top: 20px;
`;

interface EditVehicleProps extends DialogProps {
  title: string;
  navigator: ResourceNavigator;
  navigatorIndex: number;
  listGroup: Array<VehicleGroup> | undefined;
  listGroupSelected: Array<VehicleGroup>;
  vehicleSelected: VehicleWithOrg | undefined;
  connectedDevice: Array<DastrackV>;
  vehicleContacts: Contact[];
  onCloseDialog: () => void;
  onSuccessEdit: () => void;
  onSuccessBind: () => void;
  onSelectNavigatorIndex: (index: number) => void;
}

const EditVehicle: React.FC<EditVehicleProps> = ({
  open,
  title,
  navigator,
  navigatorIndex,
  listGroup,
  connectedDevice,
  vehicleSelected,
  vehicleContacts,
  listGroupSelected,
  onCloseDialog,
  onSuccessEdit,
  onSuccessBind,
  onSelectNavigatorIndex,
}) => {
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const { policies, role: userProjectRole } = useAppSelector(
    (store) => store.projects,
  );
  const { t } = useTranslation('project-setting');
  const [checkedNavigator, setCheckedNavigator] = useState(
    new Array(navigator.length).fill(false),
  );
  const [listContact, setListContact] = useState<string[]>(
    vehicleContacts.map((contact) => contact.id),
  );
  const [saveButtonStatus, setSaveButtonStatus] = useState<UpdateStatus>();
  const [groupIdWillAssign, setGroupIdWillAssign] = useState<Array<string>>([]);
  const [newVehicleAssign, setNewVehicleAssign] = useState<VehicleAssign>();
  const [newDasIdAssign, setNewDasIdAssign] = useState<Array<DasIdWillAssign>>(
    [],
  );

  const [initDasId, setInitDasId] = useState<Array<DasIdWillAssign>>([]);
  const [status, setStatus] = useState<StatusType>('default');
  const [message, setMessage] = useState('');
  const hasBindDevicePolicy = useHasPolicy(policies, 'WriteDeviceBinding');

  const { mutateAsync: mutateUpdateVehicle } = useMutation({
    mutationFn: updateVehicle,
  });

  const { mutateAsync: mutateUploadAvatar } = useMutation({
    mutationFn: uploadVehicleAvatar,
  });

  const { mutateAsync: mutateUnbindContact } = useMutation({
    mutationFn: unBindProjectContact,
  });

  const { mutateAsync: mutateBindContact } = useMutation({
    mutationFn: bindProjectContact,
  });

  const { mutateAsync: mutateBindDastrackV } = useMutation({
    mutationFn: bindDastrackVToVehicle,
  });

  const { mutateAsync: mutateUnbindDastrackV } = useMutation({
    mutationFn: unbindDastrackVFromVehicle,
  });

  const { mutateAsync: mutateSetVehicleToGroup } = useMutation({
    mutationFn: setVehicleToGroup,
  });

  const initContact = vehicleContacts.map((contact) => contact.id);

  const filteredGroups = useFilteredGroups(
    'WriteOneVehicleGroup',
    listGroup ?? [],
    userProjectRole,
    policies,
  );

  useEffect(() => {
    const updateChecked = checkedNavigator.map((_, index) =>
      index === navigatorIndex ? true : false,
    );
    setCheckedNavigator(updateChecked);
  }, [navigatorIndex]);

  useEffect(() => {
    setNewVehicleAssign({
      id: vehicleSelected?.id ?? '',
      name: vehicleSelected?.name ?? '',
      areaId: vehicleSelected?.areaId ?? '',
      alertInterval: vehicleSelected?.alertInterval ?? 0,
      imageURL: vehicleSelected?.imageURL ?? '',
      orgId: vehicleSelected?.orgId ?? '',
      bindingDastrackVs: vehicleSelected?.bindingDastrackVs ?? [],
      plateNumber: vehicleSelected?.plateNumber ?? '',
      groupIds: vehicleSelected?.groupIds ?? [],
      remark: vehicleSelected?.remark ?? '',
      bindingDevices: [],
      imageUrl: null,
      type: 'vehicle',
    });
  }, [vehicleSelected]);

  useEffect(() => {
    setGroupIdWillAssign(listGroupSelected.map((group) => group.id));
  }, [listGroupSelected]);

  useEffect(() => {
    if (connectedDevice.length > 0) {
      const init: Array<DasIdWillAssign> = connectedDevice.map((dv) => ({
        id: dv.id,
        dasId: dv.dasId,
        name: dv.dasId,
        batteryLevel: dv.shadow.dataPoint.batteryLevel.value
          ? dv.shadow.dataPoint.batteryLevel.value
          : 0,
      }));
      setNewDasIdAssign(init);
      setInitDasId(init);
    } else {
      setNewDasIdAssign([]);
      setInitDasId([]);
    }
  }, [connectedDevice]);

  const handleCloseEditDialog = () => {
    onCloseDialog();
    setNewVehicleAssign(undefined);
    setNewDasIdAssign([]);
    setGroupIdWillAssign([]);
    onSelectNavigatorIndex(1);
    setStatus('default');
    setMessage('');
  };

  const couldBindDevice =
    userProjectRole === 'owner' ||
    userProjectRole === 'admin' ||
    hasBindDevicePolicy;

  const handleUpdateVehicle = async (
    vehicleData: VehicleAssign,
    vehicleId: string,
  ) => {
    const {
      name,
      areaId,
      remark,
      alertInterval,
      groupIds,
      orgId,
      plateNumber,
    } = vehicleData;

    const res = await mutateUpdateVehicle({
      projectId: projectId as string,
      vehicleId,
      payload: {
        name,
        areaId: areaId,
        remark: remark ?? '',
        alertInterval: alertInterval ?? 0,
        groupIds,
        orgId,
        plateNumber: plateNumber ?? undefined,
      },
    });
    return res.data.data;
  };

  const handleUpdoadAvatar = async (vehicleId: string, file: File) => {
    await mutateUploadAvatar({
      projectId: projectId ?? '',
      vehicleId,
      imageFile: file,
    });
  };

  const unbindContacts = async (contactIds: string[], id: string) => {
    const requestUnbindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateUnbindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: id,
      });
      return data;
    });
    await Promise.all(requestUnbindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries({
      queryKey: ['project-contacts', projectId as string],
    });
  };

  const bindContacts = async (contactIds: string[], id: string) => {
    const requestBindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateBindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: id,
        type: 'vehicle',
      });
      return data;
    });
    await Promise.all(requestBindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries({
      queryKey: ['project-contacts', projectId as string],
    });
  };

  const handleBindDastrackV = async (dasIds: DasIdWillAssign[], id: string) => {
    const requestBindDastrackV = dasIds.map(async (dasId) => {
      const { data } = await mutateBindDastrackV({
        projectId: projectId as string,
        dasId: dasId.name,
        vehicleId: id,
      });
      return data;
    });
    await Promise.all(requestBindDastrackV).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    });
  };

  const handleUnbindDastrackV = async (dasIds: DasIdWillAssign[]) => {
    const requestUnbindDastrackV = dasIds.map(async (d) => {
      const { data } = await mutateUnbindDastrackV({
        dasId: d.name,
        projectId: projectId as string,
      });
      return data;
    });

    await Promise.all(requestUnbindDastrackV).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    });
  };

  const handleSetVehicleToGroup = async (
    vehicleId: string,
    groupIds: string[],
  ) => {
    await mutateSetVehicleToGroup({
      projectId: projectId as string,
      groupIds,
      vehicleId,
    });
  };

  const handleSubmit = async () => {
    setSaveButtonStatus('loading');
    setStatus('default');
    try {
      if (!newVehicleAssign) return;
      await handleUpdateVehicle(newVehicleAssign, newVehicleAssign.id);

      if (newVehicleAssign.avatarFile) {
        await handleUpdoadAvatar(
          newVehicleAssign.id,
          newVehicleAssign.avatarFile,
        );
      }

      if (groupIdWillAssign.length > 0) {
        await handleSetVehicleToGroup(newVehicleAssign.id, groupIdWillAssign);
      }

      // UNBIND CONTACT REQUEST
      const listContactUnbind = initContact.filter(
        (id) => !listContact.includes(id),
      );
      if (listContactUnbind.length > 0) {
        await unbindContacts(listContactUnbind, newVehicleAssign.id);
      }

      // BIND CONTACT REQUEST
      const listContactWillBind = listContact.filter(
        (id) => !initContact.includes(id),
      );
      if (listContactWillBind.length > 0) {
        await bindContacts(listContactWillBind, newVehicleAssign.id);
      }

      const dasIdWillUnbind = initDasId.filter(
        (item) => !newDasIdAssign.some((dw) => dw.name === item.name),
      );

      // BIND DASTRACK-V
      if (couldBindDevice && newDasIdAssign.length > 0) {
        await handleBindDastrackV(newDasIdAssign, newVehicleAssign.id);
      }

      // UNBIND
      if (couldBindDevice && dasIdWillUnbind.length > 0) {
        await handleUnbindDastrackV(dasIdWillUnbind);
      }

      const onSuccess = () => {
        setSaveButtonStatus('success');
        onSuccessEdit();
        onSuccessBind();
      };

      const resetStatus = () => {
        setSaveButtonStatus(undefined);
        handleCloseEditDialog();
      };
      const timer = setTimeout(() => {
        onSuccess();
        const timer2 = setTimeout(() => {
          resetStatus();
          clearTimeout(timer2);
        }, 500);
        clearTimeout(timer);
      }, 500);
    } catch (err) {
      setSaveButtonStatus(undefined);
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    }
  };

  const handleVehicleData = (newData: VehicleAssign) => {
    setNewVehicleAssign({ ...newData, groupIds: [...groupIdWillAssign] });
  };

  const handleOnChange = (value: any) => {
    const contactId = value;
    const isContain = listContact.includes(contactId);
    if (!isContain) {
      setListContact([...listContact, contactId]);
    } else {
      setListContact(listContact.filter((item) => item !== contactId));
    }
  };

  const isSaveable = () => {
    if (vehicleSelected && newVehicleAssign) {
      const isGroupIdsEqual = checkIsArrayEqual(
        vehicleSelected.groupIds,
        groupIdWillAssign,
      );
      const isDasIdsEqual = checkIsDasIdEqual(initDasId, newDasIdAssign);
      const isFormEdited = checkIsFormVehicleEdited(
        vehicleSelected,
        newVehicleAssign,
      );
      const isPathIdEqual = checkIdEqual(
        vehicleSelected.areaId,
        newVehicleAssign.areaId,
      );

      const isAvatarUpdate = checkIsAvatarUpdated(newVehicleAssign.avatarFile);

      const isContactsEqual = checkIsArrayEqual(initContact, listContact);

      if (
        !isGroupIdsEqual ||
        !isDasIdsEqual ||
        isFormEdited ||
        !isPathIdEqual ||
        isAvatarUpdate ||
        !isContactsEqual
      ) {
        return true;
      }
    }

    return false;
  };

  let saveButtonStatusIcon;
  switch (saveButtonStatus) {
    case 'loading':
      saveButtonStatusIcon = <LoadingButtonSvgIcon />;
      break;
    case 'success':
      saveButtonStatusIcon = <CompleteButtonSvgIcon />;
      break;
    default:
      saveButtonStatusIcon = <SaveSvgIcon />;
  }

  return (
    <ManagementDialog
      open={open}
      onClose={handleCloseEditDialog}
      status={status}
      message={message}
      title={title}
    >
      <DialogContent>
        <CheckList>
          {navigator.map((item, index) => {
            let checked: boolean = false;

            switch (item.name) {
              case 'group':
                checked = true;
                break;
              case 'path':
                checked = checkIsPathSelected(newVehicleAssign?.areaId);
                break;
              case 'vehicle-basic-information':
                checked = checkIsVehicleInformationValid(newVehicleAssign);
                break;
              case 'emergency-contact':
                checked = checkResourceHasContact(listContact);
                break;
              case 'connected-device':
                checked = hasAssignedDevice(newDasIdAssign);
                break;
            }

            return (
              <CheckListItem
                name={t(`navigator.${item.name}`)}
                required={item.required}
                checked={checked}
                onClick={() => onSelectNavigatorIndex(index)}
                selected={checkedNavigator[index]}
                key={index}
                data-cy={`btn-list-navigator-plant-${item.name}`}
              />
            );
          })}
        </CheckList>
        <ContentWrapper className="content wrapper">
          {navigator[navigatorIndex].name === 'group' && (
            <GroupEdit
              groupIdsSelected={groupIdWillAssign}
              handleGroupAssign={(v) => setGroupIdWillAssign(v)}
              listGroup={filteredGroups}
              resource="vehicle"
            />
          )}
          {navigator[navigatorIndex].name === 'vehicle-basic-information' && (
            <VehicleInformation
              data={newVehicleAssign}
              onChange={handleVehicleData}
            />
          )}
          {navigator[navigatorIndex].name === 'path' && (
            <PathEdit
              onChange={(v) => {
                const prevData = { ...newVehicleAssign };
                prevData.areaId = v;
                setNewVehicleAssign(prevData as VehicleAssign);
              }}
              selectedPath={newVehicleAssign?.areaId}
            />
          )}
          {navigator[navigatorIndex].name === 'emergency-contact' && (
            <ContactContent
              onChange={handleOnChange}
              listContact={listContact}
            />
          )}
          {navigator[navigatorIndex].name === 'connected-device' && (
            <>
              {couldBindDevice ? (
                <DeviceVehicle
                  data={newDasIdAssign}
                  handleDasIDWillAssign={(dasId) => setNewDasIdAssign(dasId)}
                />
              ) : (
                <ManagementNoPermissionContent />
              )}
            </>
          )}
        </ContentWrapper>
      </DialogContent>
      <DialogActions>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': {
              background: mainTheme.color.secondary.$60,
            },
            marginRight: 'auto',
          }}
          color="secondary"
          onClick={handleCloseEditDialog}
          data-cy="btn-cancel-edited-plant"
        >
          {t('cancel')}
        </DialogButton>
        <ConfirmButton
          sx={{
            '& .MuiButton-startIcon > svg': {
              width: '32px',
              height: '32px',
            },
          }}
          startIcon={saveButtonStatusIcon}
          status={saveButtonStatus}
          onClick={handleSubmit}
          disabled={!isSaveable()}
          data-cy="btn-save-edited-plant"
        >
          {t('save')}
        </ConfirmButton>
      </DialogActions>
    </ManagementDialog>
  );
};

export default EditVehicle;
