import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { FieldGroup } from '../../types';

import { getProjectGroups } from '../../apis/ResourceApi';

export type FieldGroupMapData = {
  buildingGroupMap: {
    [id: string]:
      | (FieldGroup & {
          roomList: Array<{
            id: string;
            name: string;
            imageUrl: string | null;
          }>;
        })
      | undefined;
  };
  fieldGroupMap: { [id: string]: FieldGroup | undefined };
};

type UseProjectFieldGroupMapParams = {
  projectId: string | undefined;
  options?: Omit<UseQueryOptions<FieldGroupMapData>, 'queryKey' | 'queryFn'>;
};

const useProjectFieldGroupMap = ({
  projectId,
  options,
}: UseProjectFieldGroupMapParams) => {
  return useQuery<FieldGroupMapData>({
    queryKey: ['get-field-group-map', projectId],
    queryFn: async () => {
      const buildingGroupMap: {
        [id: string]:
          | (FieldGroup & {
              roomList: Array<{
                id: string;
                name: string;
                remark: string;
                imageUrl: string | null;
              }>;
            })
          | undefined;
      } = {};
      const fieldGroupMap: { [id: string]: FieldGroup | undefined } = {};

      const load = async (nextCursor?: string) => {
        const res = await getProjectGroups({
          projectId: projectId as string,
          resourceType: 'field',
          params: {
            nextCursor,
          },
        });

        res.data.data.forEach((group) => {
          if (!group.isDefault) {
            const fieldGroup = group as FieldGroup;
            if (!fieldGroup.buildingGroupId) {
              buildingGroupMap[fieldGroup.id] = { ...fieldGroup, roomList: [] };
            }
          }
        });

        res.data.data.forEach((group) => {
          if (!group.isDefault) {
            const fieldGroup = group as FieldGroup;
            if (fieldGroup.buildingGroupId) {
              buildingGroupMap[fieldGroup.buildingGroupId]?.roomList.push({
                id: fieldGroup.id,
                name: fieldGroup.name,
                remark: fieldGroup.remark,
                imageUrl: fieldGroup.imageUrl,
              });
              fieldGroupMap[fieldGroup.id] = fieldGroup;
            }
          }
        });

        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };

      await load();

      return {
        buildingGroupMap,
        fieldGroupMap,
      };
    },
    enabled: !!projectId,
    ...options,
  });
};

export default useProjectFieldGroupMap;
