import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  CircularProgress,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  FormControl,
  styled,
  // TextareaAutosize,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import Input from '../../../../components/TextField/Input';

import CleanDateSvgIcon from '../../../../assets/SvgIcon/CleanDateSvgIcon';
import mainTheme from '../../../../theme';

const Dialog = styled(MuiDialog)`
  & .MuiPaper-root {
    width: 480px;
    background: ${({ theme }) => theme.color.secondary.$80};
    border-radius: 10px;
  }
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  color: white;
  font-family: 'Montserrat';
  font-weight: 700;
  padding: 8px 8px 8px 16px;

  & .MuiDialogTitle-root {
    flex: 1;
    padding: 0;
  }

  & .MuiSvgIcon-root {
    cursor: pointer;
    width: 32px;
    height: 32px;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  ${({ theme }) => ({ ...theme.externalTypography.body3 })}
  padding: 20px 80px;
  color: white;

  & .MuiFormControl-root {
    width: 100%;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    & .MuiInputBase-root {
      width: 100%;
    }

    & textarea {
      ${({ theme }) => ({ ...theme.typography.body2 })}
      width: 100%;
      font-family: 'Noto Sans TC', 'Noto Sans';
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      outline: none;
      color: white;
      padding: 10px;
      border-color: transparent;
      resize: none;

      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }

      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.5);
      }
    }
  }
`;

const DialogActions = styled(MuiDialogActions)`
  justify-content: space-between;
  padding: 24px;
`;

interface GroupDialogProps extends Omit<DialogProps, 'onClose'> {
  name?: string;
  remark?: string;
  title: string;
  placeholder?: string;
  submitText: string;
  operationApi: (params: {
    projectId: string;
    name: string;
    remark?: string;
  }) => Promise<any>;
  onClose: () => void;
  onSuccess: () => void;
}

const CreateGroupDialog: React.FC<GroupDialogProps> = ({
  name,
  // remark,
  open,
  title,
  placeholder,
  onClose,
  operationApi,
  submitText,
  onSuccess: onSuccessOperation,
}) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const [localName, setLocalName] = useState<string>(name ?? '');
  // const [localRemark, setLocalRemark] = useState<string>(remark ?? '');
  const operationRequest = useMutation<
    any,
    unknown,
    { projectId: string; name: string; remark?: string }
  >({
    mutationFn: async (params) => operationApi(params),
  });

  const saveable = () => {
    if (localName === '') return false;
    return true;
  };

  const handleOperation = () => {
    if (projectId) {
      operationRequest.mutate(
        {
          projectId,
          name: localName,
          // remark: localRemark,
        },
        {
          onSuccess: onSuccessOperation,
        },
      );
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    if (operationRequest.isError) {
      if (operationRequest.error instanceof Error) {
        alert(operationRequest.error.message);
      } else {
        alert(JSON.stringify(operationRequest.error));
      }
    }
  }, [operationRequest.isError]);

  return (
    <Dialog open={open}>
      <Header>
        <MuiDialogTitle>{title}</MuiDialogTitle>
        <CleanDateSvgIcon onClick={onClose} />
      </Header>
      <DialogContent>
        <FormControl>
          <label htmlFor="group-name-input">{t('name')}</label>
          <Input
            id="group-name-input"
            name="group-name"
            placeholder={placeholder}
            value={localName}
            onChange={(e) => setLocalName(e.currentTarget.value)}
          />
        </FormControl>
        {/* <FormControl>
          <label htmlFor="group-remark-input">{t('remark')}</label>
          <TextareaAutosize
            id="group-remark-input"
            name="group-remark"
            maxRows={4}
            minRows={4}
            value={localRemark}
            onChange={(e) => setLocalRemark(e.currentTarget.value)}
          />
        </FormControl> */}
      </DialogContent>
      <DialogActions>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': { background: mainTheme.color.secondary.$60 },
          }}
          color="secondary"
          onClick={handleOnClose}
        >
          {t('cancel')}
        </DialogButton>
        <DialogButton
          variant="contained"
          color="primary"
          disabled={operationRequest.isPending || !saveable()}
          onClick={handleOperation}
        >
          {operationRequest.isPending && (
            <CircularProgress
              sx={{ color: 'white', mr: '4px' }}
              size={'1rem'}
            />
          )}
          {submitText}
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGroupDialog;
