import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from '@beeinventor/dasiot-react-component-lib';
import {
  Avatar,
  capitalize,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  styled,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { addMonths, endOfMonth, format, startOfMonth } from 'date-fns';

import { Plant, Resource, Worker } from '../../../types/Resource';

import { getProjectPlants } from '../../../apis/DastrackApi';
import { useAppSelector } from '../../../hooks';
import useAttendanceOfPlantData from '../../../hooks/attendance/useAttendanceOfPlantData';
import useAttendanceOfWorkerData from '../../../hooks/attendance/useAttendanceOfWorkerData';
import useAttendanceOfWorkerDataTime from '../../../hooks/attendance/useAttendanceOfWorkerDataTime';
import useAreaMap from '../../../hooks/useAreaMap';

import Loading from '../../../components/Loading';
import OrgText from '../../../components/OrgText';

import CleanDateSvgIcon from '../../../assets/SvgIcon/CleanDateSvgIcon';
import { getTimeOffset } from '../../../utils/getTimeZone';
import { getDateFnsLocale } from '../../../utils/language';

import AttendanceReportDaysTable from './AttendanceReportDaysTable';
import AttendanceReportHourTable from './AttendanceReportHourTable';
import DetailTimeReportDaysTable from './DetailTimeReportDaysTable';
import DetailTimeReportHourTable from './DetailTimeReportHourTable';

const Dialog = styled(MuiDialog)`
  & .MuiPaper-root {
    ${({ theme }) => ({
      backgroundColor: theme.color.secondary.$80,
    })}
    width: 900px;
    height: 700px;
    max-width: unset;
  }
`;

const DialogTitle = styled(MuiDialogTitle)`
  ${({ theme }) => ({
    ...theme.typography.h3,
    backgroundColor: theme.color.secondary.$80,
    padding: '8px 8px 8px 16px',
    '& > span': {
      ...theme.typography.h4,
      flex: 1,
      fontWeight: 'normal',
      '&:before': {
        display: 'inline-block',
        content: '"|"',
        margin: '0 10px',
      },
    },
    borderRadius: '10px',
  })}
  display: flex;
  align-items: center;
  color: white;
`;

const DialogContent = styled(MuiDialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px;
  & > .info {
    display: flex;
    gap: 20px;
    background-color: ${({ theme }) => theme.color.secondary.$100};
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;

    & > div:nth-of-type(2) {
      ${({ theme }) => ({ ...theme.externalTypography.subtitle3 })};
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      min-width: 0;
      color: white;
      & > span {
        max-width: 300px;
        display: block;
        margin-bottom: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      & > .org {
        ${({ theme }) => theme.typography.body2};
        display: flex;
        align-items: center;
        color: ${({ theme }) => theme.color.secondary.$60};
      }
    }
    & > div.total-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  & > .table {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.secondary.$100};
    padding: 10px;
    border-radius: 4px;

    & > .control {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
    }

    & > .content {
      flex: 1;
      display: flex;
      justify-content: center;
      max-height: 432px;
      overflow-y: auto;
      padding: 13px 0;
    }
  }
`;

const WorkerAttendanceInformation = () => {
  const navigate = useNavigate();
  const { projectId, resourceId } = useParams();
  const location = useLocation();
  const resourceType = useMemo(() => {
    return location.pathname.split('/')[3] as 'worker' | 'plant';
  }, []);
  const currentProject = useAppSelector(
    (store) => store.projects.currentProject,
  );
  const featureListMap = useAppSelector((store) => store.system.featureListMap);
  const workerMap = useAppSelector(
    (store) => store.projects.resource.workerMap,
  );
  const { t, i18n } = useTranslation('report-dashboard');
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const [selectedMonth, setSelectedMonth] = useState<string>();
  const [displayType, setDisplayType] = useState<'hour' | 'day'>('day');

  const displayTypeList = useMemo(() => {
    return [
      { id: 'day', name: t('string-day'), value: 'day' },
      { id: 'hour', name: t('string-hour'), value: 'hour' },
    ];
  }, [t]);
  const { data: areaMap } = useAreaMap(
    ['get-area', projectId],
    projectId as string,
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    },
  );

  const { data: plant } = useQuery({
    queryKey: ['get-plant', resourceId],
    queryFn: async () => {
      const res = await getProjectPlants({
        projectId: projectId as string,
        params: { id: resourceId },
      });
      if (res.data.data.length === 1) {
        const data = res.data.data[0];
        data.type = 'plant';
        return data;
      }
    },
    enabled: !!projectId && !!resourceId && resourceType === 'plant',
    refetchOnWindowFocus: false,
  });

  const worker = useMemo(() => {
    return workerMap[resourceId ?? ''];
  }, [workerMap, resourceId]);

  const resource = useMemo<Resource | undefined>(() => {
    if (resourceType === 'worker') return worker;
    if (resourceType === 'plant') return plant;
  }, [worker, plant, resourceType]);

  const monthList = useMemo(() => {
    const list: { id: string; name: string; value: string | number }[] = [];
    const today = new Date();
    for (let i = 0; i < 12; i += 1) {
      const currentDate = addMonths(startOfMonth(today), -i);
      list.push({
        id: `date-${currentDate.getFullYear()}-${currentDate.getMonth()}`,
        name: format(currentDate, 'yyyy MMM', {
          locale: getDateFnsLocale(i18n.language),
        }),
        value: `date-${currentDate.getFullYear()}-${currentDate.getMonth()}`,
      });
    }
    setSelectedMonth(`date-${today.getFullYear()}-${today.getMonth()}`);
    return list;
  }, [i18n]);

  const refDate = useMemo(() => {
    if (!selectedMonth) return new Date();
    const result = /date-(?<year>\d+)-(?<month>\d+)/.exec(selectedMonth);
    if (result?.groups) {
      return new Date(
        parseInt(result.groups.year, 10),
        parseInt(result.groups.month, 10),
        1,
      );
    }
    return new Date();
  }, [selectedMonth]);

  const { data: attendanceOfWorkerData, isFetching: aWorkerIsFetching } =
    useAttendanceOfWorkerData(
      [
        'attendance-of-worker-data',
        projectId,
        resourceId,
        selectedMonth,
        displayType,
      ],
      projectId as string,
      resourceId as string,
      'all',
      {
        refDate,
        timeZone: currentProject?.timezone,
        interval: 'hour',
      },
      {
        enabled:
          resourceType === 'worker' &&
          !!projectId &&
          !!resourceId &&
          !!selectedMonth &&
          !!featureListMap.report?.metadata?.attendanceReport &&
          !!currentProject?.timezone,
        refetchOnWindowFocus: false,
      },
    );

  const { data: attendanceOfPlantData, isFetching: aPlantIsFetching } =
    useAttendanceOfPlantData(
      [
        'attendance-of-plant-data',
        projectId,
        resourceId,
        selectedMonth,
        displayType,
      ],
      projectId as string,
      resourceId as string,
      'all',
      {
        refDate,
        timeZone: currentProject?.timezone,
        interval: 'hour',
      },
      {
        enabled:
          resourceType === 'plant' &&
          !!projectId &&
          !!resourceId &&
          !!selectedMonth &&
          !!currentProject?.timezone &&
          (!featureListMap.report?.metadata?.workTimeReport ||
            !featureListMap.four_s?.metadata?.workTimeReport),
        refetchOnWindowFocus: false,
      },
    );

  const attendanceOfResourceData = useMemo(() => {
    if (resourceType === 'worker') return attendanceOfWorkerData;
    if (resourceType === 'plant') return attendanceOfPlantData;
  }, [resourceType, attendanceOfWorkerData, attendanceOfPlantData]);

  const countTable = useMemo(() => {
    if (attendanceOfResourceData) {
      const endOfMonthDate = endOfMonth(refDate);
      const table: { [date: string]: { [areaId: string]: number } } = {};
      const dateRegex = /(?<date>\d{4}-\d{2}-\d{2})/;
      Object.keys(attendanceOfResourceData).forEach((dateIndex) => {
        const currentDate = new Date(dateIndex);
        const dateText = dateRegex.exec(dateIndex)?.groups?.date;
        if (
          currentDate &&
          currentDate.getTime() >= refDate.getTime() &&
          currentDate.getTime() <= endOfMonthDate.getTime()
        ) {
          Object.entries(
            attendanceOfResourceData[dateIndex].areas ?? {},
          ).forEach(([areaId, value]) => {
            if (dateText && value.attendanceCounts > 0) {
              if (typeof table[dateText] === 'undefined') {
                table[dateText] = {};
              }
              if (typeof table[dateText][areaId] === 'undefined') {
                table[dateText][areaId] = 0;
              }
              table[dateText][areaId] += 1;
            }
          });
        }
      });
      return table;
    }
  }, [attendanceOfResourceData, refDate]);

  const { data: attendanceOfWorkerDataTime, isFetching: bIsFetching } =
    useAttendanceOfWorkerDataTime(
      [
        'attendance-of-worker-data-time',
        projectId,
        resourceId,
        selectedMonth,
        displayType,
      ],
      projectId as string,
      resourceId as string,
      'all',
      {
        refDate,
        timeZone: currentProject?.timezone,
        interval: displayType,
      },
      {
        enabled:
          resourceType === 'worker' &&
          !!projectId &&
          !!resourceId &&
          !!selectedMonth &&
          !!currentProject?.timezone &&
          !!featureListMap.report?.metadata?.workTimeReport,

        refetchOnWindowFocus: false,
      },
    );

  const countTableTime = useMemo(() => {
    if (attendanceOfWorkerDataTime) {
      const endOfMonthDate = endOfMonth(refDate);
      const table: { [date: string]: { [areaId: string]: number } } = {};
      const dateRegex = /(?<date>\d{4}-\d{2}-\d{2})/;

      Object.keys(attendanceOfWorkerDataTime).forEach((wId) => {
        const areasSelected = attendanceOfWorkerDataTime[wId]?.areas || {};
        Object.keys(areasSelected).forEach((areaKey) => {
          const intervals = areasSelected[areaKey].interval || {};
          Object.entries(intervals).forEach(([dateInterval, count]) => {
            const currentDate = new Date(dateInterval);
            if (
              count.value > 0 &&
              currentDate.getTime() >= refDate.getTime() &&
              currentDate.getTime() <= endOfMonthDate.getTime()
            ) {
              const dateText = dateRegex.exec(dateInterval)?.groups?.date;
              if (dateText) {
                if (!table[dateText]) {
                  table[dateText] = {};
                }
                table[dateText][areaKey] = count.value;
              }
            }
          });
        });
      });
      return table;
    }
  }, [attendanceOfWorkerDataTime, refDate]);

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>
        {capitalize(t(`${resourceType}-attendance-information`))}
        <span>{t('attendance-information')}</span>
        <CleanDateSvgIcon
          sx={{ cursor: 'pointer', width: '32px', height: '32px' }}
          onClick={handleClose}
        />
      </DialogTitle>
      <DialogContent>
        <div className="info">
          <Avatar
            sx={{ width: '100px', height: '100px' }}
            variant="rounded"
            src={resource?.imageURL ?? ''}
          />
          <div>
            <span title={resource?.name}>{resource?.name}</span>
            <div className="org">
              <OrgText
                sx={{ mr: '10px' }}
                orgColor={orgMap[resource?.orgId ?? '']?.color}
                name={orgMap[resource?.orgId ?? '']?.name}
              />{' '}
              {resource?.type === 'worker' &&
                (resource as Worker)?.bindingDasloops.join(',')}
              {resource?.type === 'plant' &&
                (resource as Plant)?.bindingDastracks.join(',')}
            </div>
          </div>
        </div>
        <div className="table">
          <div className="control">
            <Dropdown
              sx={{ flex: 1, padding: '0 0 0 16px' }}
              mode="dark"
              list={monthList}
              selectedId={selectedMonth}
              popperProps={{
                disablePortal: true,
                sx: {
                  zIndex: 2000,
                  '& > .MuiBox-root': {
                    maxHeight: '200px',
                    overflow: 'auto',
                  },
                },
              }}
              onSelect={(v) => {
                setSelectedMonth(v as string);
              }}
            />
            <Dropdown
              sx={{ flex: 1, padding: '0 0 0 16px' }}
              mode="dark"
              list={displayTypeList}
              selectedId={displayType}
              popperProps={{
                disablePortal: true,
                sx: {
                  zIndex: 2000,
                },
              }}
              onSelect={(v) => {
                setDisplayType(v as 'day' | 'hour');
              }}
            />
          </div>
          <div className="content">
            {aWorkerIsFetching || aPlantIsFetching || bIsFetching ? (
              <Loading />
            ) : (
              <>
                {displayType === 'day' &&
                  resourceType === 'worker' &&
                  featureListMap.report?.metadata?.workTimeReport && (
                    <DetailTimeReportDaysTable
                      title={
                        monthList.filter((d) => d.id === selectedMonth)[0]?.name
                      }
                      refDate={refDate}
                      areaMap={areaMap}
                      countTable={countTableTime}
                    />
                  )}
                {displayType === 'day' &&
                  ((resourceType === 'worker' &&
                    featureListMap.report?.metadata?.attendanceReport) ||
                    (resourceType === 'plant' &&
                      featureListMap.report?.metadata
                        .plantAttendanceReport)) && (
                    <AttendanceReportDaysTable
                      title={
                        monthList.filter((d) => d.id === selectedMonth)[0]?.name
                      }
                      refDate={refDate}
                      countTable={countTable}
                      areaMap={areaMap}
                    />
                  )}
                {displayType === 'hour' &&
                  resourceType === 'worker' &&
                  featureListMap.report?.metadata?.workTimeReport && (
                    <DetailTimeReportHourTable
                      refDate={refDate}
                      timeZone={getTimeOffset(currentProject?.timezone)}
                      areaMap={areaMap}
                      attendanceOfWorkerData={
                        attendanceOfWorkerDataTime?.[resourceId ?? '']
                      }
                    />
                  )}
                {displayType === 'hour' &&
                  ((resourceType === 'worker' &&
                    featureListMap.report?.metadata?.attendanceReport) ||
                    (resourceType === 'plant' &&
                      featureListMap.report?.metadata
                        .plantAttendanceReport)) && (
                    <AttendanceReportHourTable
                      refDate={refDate}
                      timeZone={getTimeOffset(currentProject?.timezone)}
                      areaMap={areaMap}
                      attendanceOfReasourceData={attendanceOfResourceData}
                    />
                  )}
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WorkerAttendanceInformation;
