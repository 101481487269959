import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  CircularProgress,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  DasIdWillAssign,
  PlantGroup,
  PlantWillAssign,
  StatusType,
  UpdateStatus,
} from '../../../../types';

import {
  bindDastrackToPlant,
  createPlant,
  uploadPlantAvatar,
} from '../../../../apis/DastrackApi';
import { bindProjectContact } from '../../../../apis/ProjectApi';
import { useAppSelector } from '../../../../hooks';
import useFilteredGroups from '../../../../hooks/useFilteredGroups';
import useHasPolicy from '../../../../hooks/useHasPolicy';

import CheckListItem from '../../../../components/CheckListItem';
import ConfirmButton from '../../../../components/ConfirmButton';
import ManagementDialog from '../../../../components/Dialog/ManagementDialog';
import ManagementNoPermissionContent from '../../../../components/ManagementNoPermissionContent';

import CompleteButtonSvgIcon from '../../../../assets/SvgIcon/CompleteButtonSvgIcon';
import mainTheme from '../../../../theme';
import { navigatorPlant as navigator } from '../../../../utils/common';
import ContactContent from '../../management/edit-content/ContactEdit';
import GroupEdit from '../../management/edit-content/GroupEdit';
import {
  checkResourceHasContact,
  hasAssignedDevice,
} from '../../management/validation/management-validation';
import { checkPlantBasicInformationAvalible } from '../../management/validation/plant-validation';

import BasicInformationContent from './BasicInformationContent';
import ConnectedDeviceContent from './ConnectedDeviceContent';

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  display: flex;
  flex: 1;
`;

const ContentWrapper = styled('div')`
  width: 340px;
  border-radius: 4px;
  display: flex;
  flex: 1;
`;

const DialogActions = styled(MuiDialogActions)`
  display: flex;
  padding: 24px;
  height: 80px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  width: 280px;
  margin-top: 20px;
`;

const handleTextStatus = (t, statusIcon) => {
  switch (statusIcon) {
    case 'loading':
      return <CircularProgress size={16} />;
    case 'success':
      return <CompleteButtonSvgIcon />;
    default:
      return <>{t('save-and-close')}</>;
  }
};

interface TargetOperationDialogProps extends DialogProps {
  title: string;
  onCloseDialog: () => void;
  plantGroupProject: Array<PlantGroup> | undefined;
  plantGroupSelected: PlantGroup | undefined;
  onSuccessCreatePlant: () => void;
  onSuccessBind: () => void;
}

const TargetOperationDialog: React.FC<TargetOperationDialogProps> = ({
  open,
  title,
  plantGroupProject,
  onCloseDialog,
  plantGroupSelected,
  onSuccessCreatePlant,
  onSuccessBind,
}) => {
  const projectId = useParams().projectId;
  const { policies, role: userProjectRole } = useAppSelector(
    (store) => store.projects,
  );

  const { mutateAsync: mutateCreatePlant } = useMutation({
    mutationFn: createPlant,
  });

  const { mutateAsync: mutateUploadAvatar } = useMutation({
    mutationFn: uploadPlantAvatar,
  });

  const { mutateAsync: mutateBindProjectContact } = useMutation({
    mutationFn: bindProjectContact,
  });

  const { t } = useTranslation('project-setting');
  const queryClient = useQueryClient();
  const [indexNavigator, setIndexNavigator] = useState(0);
  const [checkedNavigator, setCheckedNavigator] = useState(
    new Array(navigator.length).fill(false),
  );

  const [listContact, setListContact] = useState<string[]>([]);
  const [updateStatus, setUpdateStatus] = useState<UpdateStatus>();
  const [groupIdWillAssign, setGroupIdWillAssign] = useState<Array<string>>([]);
  const [plantWillAssign, setplantWillAssign] = useState<PlantWillAssign>();
  const [dastrackWillAssign, setDastrackWillAssign] = useState<
    Array<DasIdWillAssign>
  >([]);
  const [status, setStatus] = useState<StatusType>('default');
  const [message, setMessage] = useState('');
  const hasBindDevicePolicy = useHasPolicy(policies, 'WriteDeviceBinding');

  const filteredGroups = useFilteredGroups(
    'WriteOnePlantGroup',
    plantGroupProject ?? [],
    userProjectRole,
    policies,
  );

  useEffect(() => {
    const updateChecked = checkedNavigator.map((_, index) =>
      index === indexNavigator ? true : false,
    );
    setCheckedNavigator(updateChecked);
  }, [indexNavigator]);

  const next = () => {
    setIndexNavigator(indexNavigator + 1);
  };

  const previous = () => {
    setIndexNavigator(indexNavigator - 1);
  };

  useEffect(() => {
    const groupsName: string[] = [];
    if (plantGroupSelected) {
      groupsName.push(plantGroupSelected.id);
    }
    setGroupIdWillAssign(groupsName);
  }, [open, plantGroupProject, plantGroupSelected]);

  const enablePrevious = useMemo(() => {
    if (indexNavigator > 0) {
      return true;
    } else {
      return false;
    }
  }, [indexNavigator]);

  const enableNext = useMemo(() => {
    if (indexNavigator < navigator.length - 1) {
      return true;
    } else {
      return false;
    }
  }, [indexNavigator]);

  const handleCloseEditDialog = () => {
    onCloseDialog();
    setplantWillAssign(undefined);
    setDastrackWillAssign([]);
    setGroupIdWillAssign([]);
    setIndexNavigator(0);
    setStatus('default');
    setMessage('');
  };

  const couldBindDevice =
    userProjectRole === 'owner' ||
    userProjectRole === 'admin' ||
    hasBindDevicePolicy;

  const bindContactToPlant = async (contactIds: string[], plantId: string) => {
    const requestUnbindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateBindProjectContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: plantId,
        type: 'plant',
      });
      return data;
    });

    await Promise.all(requestUnbindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    });
    queryClient.invalidateQueries({
      queryKey: ['project-contacts', projectId as string],
    });
  };

  const handleSubmitNewPlant = async () => {
    try {
      setUpdateStatus('loading');
      setStatus('default');

      if (plantWillAssign) {
        // ===================== Basic Information ===================== //
        const { data: res } = await mutateCreatePlant({
          projectId: projectId ?? '',
          payload: {
            orgId: plantWillAssign.orgId,
            name: plantWillAssign.name,
            model: plantWillAssign.model,
            serialNumber: plantWillAssign.serialNumber,
            permitNumber: plantWillAssign.permitNumber,
            permitIssuer: plantWillAssign.permitIssuer,
            permitExpiryDate: plantWillAssign.permitExpiryDate,
            safetyLoad: plantWillAssign.safetyLoad ?? undefined,
            yearOfProduction: plantWillAssign.yearOfProduction ?? undefined,
            brand: plantWillAssign.brand ?? undefined,
            numberReading: plantWillAssign.numberReading ?? undefined,
            remark: plantWillAssign.remark ? plantWillAssign.remark : undefined,
            groupIds: groupIdWillAssign,
            plantType: plantWillAssign.plantType,
          },
        });
        const plantId = res.data.id;
        if (plantId) {
          // ===================== Plant Avatar ===================== //
          if (plantWillAssign.avatarFile) {
            await mutateUploadAvatar({
              projectId: projectId ?? '',
              plantId: plantId,
              imageFile: plantWillAssign.avatarFile,
            });
          }

          // ===================== Bind Contact Plant ===================== //
          if (listContact.length > 0) {
            bindContactToPlant(listContact, plantId);
          }

          // ===================== Bind to Dastrack ===================== //
          if (dastrackWillAssign.length > 0) {
            const requestBindDastrack: Promise<any>[] = [];
            dastrackWillAssign.forEach(async (d) => {
              if (d.name) {
                requestBindDastrack.push(
                  bindDastrackToPlant({
                    projectId: projectId ?? '',
                    dasId: d.name,
                    data: {
                      plantId: plantId,
                    },
                  }),
                );
              }
            });
            await Promise.all(requestBindDastrack);
          }
        }
      }

      let timer2: NodeJS.Timeout;
      const timer = setTimeout(() => {
        setUpdateStatus('success');
        onSuccessCreatePlant();
        onSuccessBind();
        handleCloseEditDialog();

        timer2 = setTimeout(() => {
          setUpdateStatus(undefined);
        }, 500);
      }, 500);
      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } catch (err) {
      setUpdateStatus(undefined);
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    }
  };

  const handlePlantDataWIllAssign = (plantData: PlantWillAssign) => {
    setplantWillAssign({ ...plantData, groupIds: [...groupIdWillAssign] });
  };

  const handleOnChange = (value: any) => {
    const contactId = value;
    const isContain = listContact.includes(contactId);
    if (!isContain) {
      setListContact([...listContact, contactId]);
    } else {
      setListContact(listContact.filter((item) => item !== contactId));
    }
  };

  return (
    <ManagementDialog
      open={open}
      onClose={handleCloseEditDialog}
      status={status}
      message={message}
      title={title}
    >
      <DialogContent>
        <CheckList>
          {navigator.map((item, index) => {
            let checked: boolean = false;

            switch (item.name) {
              case 'group':
                checked = true;
                break;
              case 'plant-basic-information':
                checked = checkPlantBasicInformationAvalible(plantWillAssign);
                break;
              case 'emergency-contact':
                checked = checkResourceHasContact(listContact);
                break;
              case 'connected-device':
                checked = hasAssignedDevice(dastrackWillAssign);
                break;
            }

            return (
              <CheckListItem
                name={t(`navigator.${item.name}`)}
                required={item.required}
                checked={checked}
                onClick={() => setIndexNavigator(index)}
                selected={checkedNavigator[index]}
                key={index}
                data-cy={`btn-list-navigator-plant-${item.name}`}
              />
            );
          })}
        </CheckList>
        <ContentWrapper className="content wrapper">
          {navigator[indexNavigator].name === 'group' && (
            <GroupEdit
              groupIdsSelected={groupIdWillAssign}
              handleGroupAssign={(v) => setGroupIdWillAssign(v)}
              listGroup={filteredGroups}
              resource="plant"
            />
          )}
          {navigator[indexNavigator].name === 'plant-basic-information' && (
            <BasicInformationContent
              handlePlantDataWIllAssign={handlePlantDataWIllAssign}
              data={plantWillAssign}
            />
          )}
          {navigator[indexNavigator].name === 'emergency-contact' && (
            <ContactContent
              onChange={handleOnChange}
              listContact={listContact}
            />
          )}
          {navigator[indexNavigator].name === 'connected-device' && (
            <>
              {couldBindDevice ? (
                <ConnectedDeviceContent
                  data={dastrackWillAssign}
                  handleDasIDWillAssign={(dasID) =>
                    setDastrackWillAssign(dasID)
                  }
                />
              ) : (
                <ManagementNoPermissionContent />
              )}
            </>
          )}
        </ContentWrapper>
      </DialogContent>
      <DialogActions>
        {enablePrevious && (
          <DialogButton
            sx={{
              '&.Mui-disabled': {
                color: 'white',
              },
              '&:hover, &:active': {
                background: mainTheme.color.secondary.$60,
              },
              marginRight: 'auto',
            }}
            color="secondary"
            startIcon={<ChevronLeft />}
            onClick={previous}
          >
            {t('previous')}
          </DialogButton>
        )}
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': {
              background: mainTheme.color.secondary.$60,
            },
            margin: `0 ${enablePrevious ? '0' : 'auto'} 0 ${
              enablePrevious ? 'auto' : '0'
            }`,
          }}
          color="secondary"
          onClick={handleCloseEditDialog}
          data-cy="btn-cancel-add-new-plant"
        >
          {t('cancel')}
        </DialogButton>
        {enableNext ? (
          <DialogButton
            variant="contained"
            color="primary"
            disabled={false}
            onClick={next}
            endIcon={<ChevronRight />}
          >
            {t('next')}
          </DialogButton>
        ) : (
          <ConfirmButton
            sx={{
              ml: 'auto',
              width: '120px',
              height: '32px',
              textTransform: 'none',
            }}
            disabled={
              !checkPlantBasicInformationAvalible(plantWillAssign) ||
              updateStatus !== undefined
            }
            status={updateStatus}
            onClick={handleSubmitNewPlant}
            data-cy="btn-submit-add-new-plant"
          >
            {handleTextStatus(t, updateStatus)}
          </ConfirmButton>
        )}
      </DialogActions>
    </ManagementDialog>
  );
};

export default TargetOperationDialog;
