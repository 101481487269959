import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogProps,
  styled,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  Contact,
  DasIdWillAssign,
  Org,
  PlantGroup,
  PlantWillAssign,
  StatusType,
} from '../../../../types';
import { Dastrack } from '../../../../types/Device';
import { Plant, ResourceNavigator } from '../../../../types/Resource';

import {
  bindDastrackToPlant,
  setPlantGroup,
  unbindDastrack,
  updatePlant,
  uploadPlantAvatar,
} from '../../../../apis/DastrackApi';
import {
  bindProjectContact,
  unBindProjectContact,
} from '../../../../apis/ProjectApi';
import { useAppSelector } from '../../../../hooks';
import useHasPolicy from '../../../../hooks/useHasPolicy';

import CheckListItem from '../../../../components/CheckListItem';
import ConfirmButton from '../../../../components/ConfirmButton';
import ManagementDialog from '../../../../components/Dialog/ManagementDialog';
import ManagementNoPermissionContent from '../../../../components/ManagementNoPermissionContent';

import CompleteButtonSvgIcon from '../../../../assets/SvgIcon/CompleteButtonSvgIcon';
import LoadingButtonSvgIcon from '../../../../assets/SvgIcon/LoadingButtonSvgIcon';
import SaveSvgIcon from '../../../../assets/SvgIcon/SaveSvgIcon';
import mainTheme from '../../../../theme';
import { checkIsArrayEqual } from '../../../../utils/checkArrayEqual';
import ContactContent from '../../management/edit-content/ContactEdit';
import {
  checkIsAvatarUpdated,
  checkIsDasIdEqual,
  checkResourceHasContact,
  hasAssignedDevice,
} from '../../management/validation/management-validation';
import {
  checkIsFormPlantEdited,
  checkPlantBasicInformationAvalible,
} from '../../management/validation/plant-validation';
import BasicInformationContent from '../TargetOperationDialog/BasicInformationContent';
import ConnectedDeviceContent from '../TargetOperationDialog/ConnectedDeviceContent';

import PlantGroupContentEdit from './PlantGroupContentEdit';

const DialogContent = styled(MuiDialogContent)`
  padding: 10px 20px;
  display: flex;
  flex: 1;
`;

const ContentWrapper = styled('div')`
  width: 340px;
  border-radius: 4px;
  display: flex;
  flex: 1;
`;

const DialogActions = styled(MuiDialogActions)`
  display: flex;
  padding: 24px;
  height: 80px;
`;

const CheckList = styled('div')`
  display: flex;
  flex-direction: column;
  width: 280px;
  margin-top: 20px;
`;

interface EditPlantProps extends DialogProps {
  title: string;
  navigator: ResourceNavigator;
  navigatorIndex: number;
  plantSelected: (Plant & { orgDetail: Org | undefined }) | undefined;
  plantGroupProject: Array<PlantGroup> | undefined;
  plantGroupSelected: Array<PlantGroup>;
  connectedDevice: Array<Dastrack>;
  plantContacts: Contact[];
  onSuccessEditPlant: () => void;
  onSuccessBind: () => void;
  onCloseDialog: () => void;
  onSelectNavigatorIndex: (index: number) => void;
}

const EditPlant: React.FC<EditPlantProps> = ({
  title,
  navigator,
  navigatorIndex,
  plantSelected,
  plantGroupProject,
  plantGroupSelected,
  connectedDevice,
  plantContacts,
  onSuccessEditPlant,
  onSuccessBind,
  onCloseDialog,
  onSelectNavigatorIndex,
}) => {
  const { projectId } = useParams();
  const { policies, role: userProjectRole } = useAppSelector(
    (store) => store.projects,
  );
  const queryClient = useQueryClient();

  const [checkedNavigator, setCheckedNavigator] = useState(
    new Array(navigator.length).fill(false),
  );
  const { t } = useTranslation('project-setting');
  const [plantGroupWillAssign, setPlantGroupWillAssign] = useState<
    Array<string>
  >([]);
  const [saveButtonStatus, setSaveButtonStatus] = useState<
    'loading' | 'success'
  >();

  const [plantWillAssign, setPlantWillAssign] = useState<PlantWillAssign>();
  const [dastrackWillAssign, setDastrackWillAssign] = useState<
    Array<DasIdWillAssign>
  >([]);

  const [initDastrack, setInitDastrack] = useState<Array<DasIdWillAssign>>([]);
  const [status, setStatus] = useState<StatusType>('default');
  const [message, setMessage] = useState('');
  const hasBindDevicePolicy = useHasPolicy(policies, 'WriteDeviceBinding');
  const [listContact, setListContact] = useState<string[]>(
    plantContacts.map((contact) => contact.id),
  );
  const initContact = plantContacts.map((contact) => contact.id);

  const { mutateAsync: mutateUnbindContact } = useMutation({
    mutationFn: unBindProjectContact,
  });

  const { mutateAsync: mutateBindContact } = useMutation({
    mutationFn: bindProjectContact,
  });

  const { mutateAsync: mutateEditPlant } = useMutation({
    mutationFn: updatePlant,
  });

  const { mutateAsync: mutateUploadAvatar } = useMutation({
    mutationFn: uploadPlantAvatar,
  });

  const { mutateAsync: mutateUpdatePlantGroupIds } = useMutation({
    mutationFn: setPlantGroup,
  });

  const { mutateAsync: mutateBindDastrack } = useMutation({
    mutationFn: bindDastrackToPlant,
  });

  const { mutateAsync: mutateUnbindDastrack } = useMutation({
    mutationFn: unbindDastrack,
  });

  useEffect(() => {
    setPlantWillAssign({
      id: plantSelected?.id ?? '',
      name: plantSelected?.name ?? '',
      model: plantSelected?.model ?? '',
      serialNumber: plantSelected?.serialNumber ?? '',
      permitNumber: plantSelected?.permitNumber ?? '',
      permitIssuer: plantSelected?.permitIssuer ?? '',
      permitExpiryDate: plantSelected?.permitExpiryDate ?? '',
      subContractorName: plantSelected?.subContractorName ?? '',
      safetyLoad: plantSelected?.safetyLoad ?? undefined,
      orgId: plantSelected?.orgId ?? '',
      projectId: plantSelected?.projectId ?? '',
      remark: plantSelected?.remark ?? '',
      imageURL: plantSelected?.imageURL ?? '',
      groupIds: plantSelected?.groupIds ?? [],
      bindingDastracks: plantSelected?.bindingDastracks ?? [],
      bindingDascases: plantSelected?.bindingDascases ?? [],
      brand: plantSelected?.brand ?? '',
      numberReading: plantSelected?.numberReading ?? 0,
      yearOfProduction: plantSelected?.yearOfProduction ?? 0,
      plantType: plantSelected?.plantType ?? 'plant',
    });
  }, [plantSelected]);

  useEffect(() => {
    if (connectedDevice.length > 0) {
      const init: Array<DasIdWillAssign> = connectedDevice.map((dastrack) => ({
        id: dastrack.id,
        dasId: dastrack.dasId,
        name: dastrack.dasId,
        batteryLevel: dastrack.shadow.dataPoint.batteryLevel.value
          ? dastrack.shadow.dataPoint.batteryLevel.value
          : 0,
      }));
      setDastrackWillAssign(init);
      setInitDastrack(init);
    } else {
      setDastrackWillAssign([]);
      setInitDastrack([]);
    }
  }, [connectedDevice]);

  useEffect(() => {
    setPlantGroupWillAssign(plantGroupSelected.map((group) => group.id));
  }, [open, plantGroupProject, plantGroupSelected]);

  useEffect(() => {
    const updateChecked = checkedNavigator.map((_, index) =>
      index === navigatorIndex ? true : false,
    );
    setCheckedNavigator(updateChecked);
  }, [navigatorIndex]);

  const couldBindDevice =
    userProjectRole === 'owner' ||
    userProjectRole === 'admin' ||
    hasBindDevicePolicy;

  const handleCloseEditDialog = () => {
    onCloseDialog();
    setPlantWillAssign(undefined);
    setInitDastrack([]);
    setDastrackWillAssign([]);

    setPlantGroupWillAssign([]);
    onSelectNavigatorIndex(0);
    setStatus('default');
    setMessage('');
  };

  const unbindContacts = async (contactIds: string[]) => {
    const requestUnbindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateUnbindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: plantWillAssign?.id ?? '',
      });
      return data;
    });
    await Promise.all(requestUnbindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries({
      queryKey: ['project-contacts', projectId as string],
    });
  };

  const bindContacts = async (contactIds: string[]) => {
    const requestBindContact = contactIds.map(async (contactId) => {
      const { data } = await mutateBindContact({
        projectId: projectId ?? '',
        contactId,
        referenceId: plantWillAssign?.id ?? '',
        type: 'plant',
      });
      return data;
    });
    await Promise.all(requestBindContact).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
      setSaveButtonStatus(undefined);
    });
    queryClient.invalidateQueries({
      queryKey: ['project-contacts', projectId as string],
    });
  };

  const handleBindDastrack = async (dasIds: DasIdWillAssign[], id: string) => {
    const requestBindDastrack = dasIds.map(async (dasId) => {
      const { data } = await mutateBindDastrack({
        projectId: projectId as string,
        dasId: dasId.name,
        data: {
          plantId: id,
        },
      });
      return data;
    });
    await Promise.all(requestBindDastrack).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    });
  };

  const handleUnbindDastrack = async (dasIds: DasIdWillAssign[]) => {
    const requestUnbindDastrack = dasIds.map(async (d) => {
      const { data } = await mutateUnbindDastrack({
        dasId: d.name,
        projectId: projectId as string,
      });
      return data;
    });

    await Promise.all(requestUnbindDastrack).catch((err) => {
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    });
  };

  const handleSubmit = async () => {
    if (!plantWillAssign) return;
    try {
      setSaveButtonStatus('loading');
      setStatus('default');

      await mutateEditPlant({
        projectId: projectId ?? '',
        plantId: plantWillAssign.id ?? '',
        payload: {
          name: plantWillAssign.name ?? '',
          model: plantWillAssign.model ?? '',
          serialNumber: plantWillAssign.serialNumber ?? '',
          permitNumber: plantWillAssign.permitNumber ?? '',
          permitIssuer: plantWillAssign.permitIssuer ?? '',
          permitExpiryDate: plantWillAssign.permitExpiryDate ?? '',
          subContractorName: plantWillAssign.subContractorName ?? '',
          safetyLoad: plantWillAssign.safetyLoad ?? 0,
          remark: plantWillAssign.remark ?? '',
          brand: plantWillAssign.brand ?? '',
          numberReading: plantWillAssign.numberReading ?? 0,
          yearOfProduction: plantWillAssign.yearOfProduction ?? 0,
          orgId: plantWillAssign.orgId ?? '',
          plantType: plantWillAssign.plantType ?? 'plant',
        },
      });

      if (plantWillAssign?.avatarFile) {
        await mutateUploadAvatar({
          projectId: projectId ?? '',
          plantId: plantWillAssign.id ?? '',
          imageFile: plantWillAssign.avatarFile,
        });
      }

      // set plant groupids
      if (plantGroupWillAssign.length !== 0) {
        await mutateUpdatePlantGroupIds({
          projectId: projectId as string,
          plantId: plantWillAssign?.id ?? '',
          groupIds: plantGroupWillAssign,
        });
      } else {
        setMessage('Please choose at least one group');
        setStatus('error');
      }

      // UNBIND CONTACT REQUEST
      const listContactUnbind = initContact.filter(
        (id) => !listContact.includes(id),
      );
      if (listContactUnbind.length > 0) {
        unbindContacts(listContactUnbind);
      }

      // BIND CONTACT REQUEST
      const listContactWillBind = listContact.filter(
        (id) => !initContact.includes(id),
      );
      if (listContactWillBind.length > 0) {
        bindContacts(listContactWillBind);
      }

      if (couldBindDevice && dastrackWillAssign.length > 0) {
        await handleBindDastrack(dastrackWillAssign, plantWillAssign.id);
      }

      const dasIdWillUnbind = initDastrack.filter(
        (item) => !dastrackWillAssign.some((dw) => dw.name === item.name),
      );

      if (couldBindDevice && dasIdWillUnbind.length > 0) {
        await handleUnbindDastrack(dasIdWillUnbind);
      }

      let timer2: NodeJS.Timeout;
      const timer = setTimeout(() => {
        setSaveButtonStatus('success');
        onSuccessBind();
        onSuccessEditPlant();

        timer2 = setTimeout(() => {
          setSaveButtonStatus(undefined);
          handleCloseEditDialog();
        }, 500);
      }, 500);
      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } catch (err) {
      setSaveButtonStatus(undefined);
      const error = err as Error;
      setMessage(error.message);
      setStatus('error');
    }
  };

  let saveButtonStatusIcon;
  switch (saveButtonStatus) {
    case 'loading':
      saveButtonStatusIcon = <LoadingButtonSvgIcon />;
      break;
    case 'success':
      saveButtonStatusIcon = <CompleteButtonSvgIcon />;
      break;
    default:
      saveButtonStatusIcon = <SaveSvgIcon />;
  }

  const saveable = () => {
    if (plantSelected && plantWillAssign) {
      if (
        !checkIsArrayEqual(plantSelected.groupIds, plantGroupWillAssign) ||
        !checkIsDasIdEqual(initDastrack, dastrackWillAssign) ||
        !checkIsArrayEqual(initContact, listContact) ||
        checkIsAvatarUpdated(plantWillAssign.avatarFile) ||
        checkIsFormPlantEdited(plantSelected, plantWillAssign)
      ) {
        return true;
      }
    }
    return false;
  };

  const handleOnChange = (value: any) => {
    const contactId = value;
    const isContain = listContact.includes(contactId);
    if (!isContain) {
      setListContact([...listContact, contactId]);
    } else {
      setListContact(listContact.filter((item) => item !== contactId));
    }
  };

  return (
    <ManagementDialog
      open
      onClose={handleCloseEditDialog}
      status={status}
      message={message}
      title={title}
    >
      <DialogContent>
        <CheckList>
          {navigator.map((item, index) => {
            let checked: boolean = false;

            switch (item.name) {
              case 'group':
                checked = true;
                break;
              case 'plant-basic-information':
                checked = checkPlantBasicInformationAvalible(plantWillAssign);
                break;
              case 'emergency-contact':
                checked = checkResourceHasContact(listContact);
                break;
              case 'connected-device':
                checked = hasAssignedDevice(dastrackWillAssign);
                break;
            }

            return (
              <CheckListItem
                name={t(`navigator.${item.name}`)}
                required={item.required}
                checked={checked}
                onClick={() => onSelectNavigatorIndex(index)}
                selected={checkedNavigator[index]}
                key={index}
                data-cy={`btn-list-navigator-plant-${item.name}`}
              />
            );
          })}
        </CheckList>
        <ContentWrapper className="content wrapper">
          {navigator[navigatorIndex].name === 'group' && (
            <PlantGroupContentEdit
              plantGroupProject={plantGroupProject}
              plantGroupIdsSelected={plantGroupWillAssign}
              handlePlantGroupWIllAssign={(groups) =>
                setPlantGroupWillAssign(groups)
              }
            />
          )}
          {navigator[navigatorIndex].name === 'plant-basic-information' && (
            <BasicInformationContent
              handlePlantDataWIllAssign={(plantData) =>
                setPlantWillAssign(plantData)
              }
              data={plantWillAssign}
            />
          )}
          {navigator[navigatorIndex].name === 'emergency-contact' && (
            <ContactContent
              onChange={handleOnChange}
              listContact={listContact}
            />
          )}
          {navigator[navigatorIndex].name === 'connected-device' && (
            <>
              {couldBindDevice ? (
                <ConnectedDeviceContent
                  data={dastrackWillAssign}
                  handleDasIDWillAssign={(dasIds) =>
                    setDastrackWillAssign(dasIds)
                  }
                />
              ) : (
                <ManagementNoPermissionContent />
              )}
            </>
          )}
        </ContentWrapper>
      </DialogContent>
      <DialogActions>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': {
              background: mainTheme.color.secondary.$60,
            },
            marginRight: 'auto',
          }}
          color="secondary"
          onClick={handleCloseEditDialog}
          data-cy="btn-cancel-edited-plant"
        >
          {t('cancel')}
        </DialogButton>
        <ConfirmButton
          sx={{
            '& .MuiButton-startIcon > svg': {
              width: '32px',
              height: '32px',
            },
          }}
          startIcon={saveButtonStatusIcon}
          status={saveButtonStatus}
          onClick={handleSubmit}
          disabled={!saveable()}
          data-cy="btn-save-edited-plant"
        >
          {t('save')}
        </ConfirmButton>
      </DialogActions>
    </ManagementDialog>
  );
};

export default EditPlant;
