import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { Area } from '../types';

import { getAreasOfProject, GetProjectAreasParams } from '../apis/AreaApi';

const useAreaMap = (
  queryKey: QueryKey,
  projectId?: string,
  options?: Omit<
    UseQueryOptions<{ [index: string]: Area | undefined }>,
    'queryKey' | 'queryFn'
  >,
  params?: GetProjectAreasParams,
) => {
  return useQuery({
    queryKey,
    queryFn: async () => {
      const areaMap: { [index: string]: Area | undefined } = {};
      const load = async (nextCursor?: string) => {
        const res = await getAreasOfProject(projectId, {
          ...params,
          nextCursor,
          selectVisible: false,
        });

        res.data.data.forEach((area) => {
          areaMap[area.id] = area;
        });

        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };

      await load();

      return areaMap;
    },
    ...options,
  });
};

export default useAreaMap;
