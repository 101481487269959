import { useEffect, useRef } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { PagedResponse, ResourceType } from '../types';
import { Dasair } from '../types/Device';
import { Asset, Resource, Structure } from '../types/Resource';

const useAssignedTargetMap = <T extends Resource | Dasair | Structure | Asset>(
  cacheName: QueryKey,
  projectId: string | undefined,
  queryFn: (args: {
    projectId: string;
    params?: any;
    resourceType?: ResourceType;
  }) => Promise<AxiosResponse<PagedResponse<T>>>,
  options?: Partial<UseQueryOptions<{ [index: string]: T | undefined }>>,
) => {
  const mapRef = useRef(new Map<string, T>());

  const query = useQuery({
    queryKey: cacheName,
    queryFn: async () => {
      const load = async (nextCursor?: string) => {
        try {
          const res = await queryFn({
            projectId: projectId as string,
            params: {
              nextCursor,
            },
          });
          res.data.data.forEach((w) => mapRef.current.set(w.id, w));
          if (res.data.paging.nextCursor) {
            return load(res.data.paging.nextCursor);
          }
        } catch (error) {
          if (error instanceof Error) {
            // Handle error if necessary
          }
        }
      };
      await load();
      return Object.fromEntries(mapRef.current);
    },
    enabled: !!projectId,
    ...options,
  });

  useEffect(() => {
    return () => mapRef.current.clear();
  }, []);

  return query;
};

export default useAssignedTargetMap;
