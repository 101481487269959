import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Area,
  Contact,
  CustomLogoData,
  FloorPlan,
  Policy,
  Project,
  ProjectRole,
  ResourceType,
  User,
} from '../types';
import { Plant, Worker } from '../types/Resource';
import { WebsocketAlert } from '../types/Websocket';

import { getProjectWorkers } from '../apis/DasloopApi';
import { getProjectPlants } from '../apis/DastrackApi';

import { sortByCreatedAt } from '../utils/Sort';

type ProjectsInit = {
  list: Project[];
  role: ProjectRole | null;
  policies: Policy[];
  currentProject: (Project & { elevation?: number }) | null;
  currentMemberMap: { [id: string]: User | undefined };
  cardLogo: CustomLogoData[];
  projectContact: Contact[];
  projectFloorPlan: FloorPlan[];
  projectArea: Area[];
  isLoadingProjectContact: boolean;
  alertWebsockets: WebsocketAlert[];
  resource: {
    workerMap: { [id: string]: Worker | undefined };
    plantMap: { [id: string]: Plant | undefined };
  };
};

const initialState: ProjectsInit = {
  list: [],
  currentProject: null,
  role: null,
  policies: [],
  cardLogo: [],
  projectContact: [],
  projectArea: [],
  projectFloorPlan: [],
  isLoadingProjectContact: true,
  currentMemberMap: {},
  alertWebsockets: [],
  resource: {
    workerMap: {},
    plantMap: {},
  },
};

export const fetchResource = createAsyncThunk<
  {
    resourceType: ResourceType;
    resourceMap:
      | { [id: string]: Worker | undefined }
      | { [id: string]: Plant | undefined };
  },
  { projectId: string; resourceType: ResourceType }
>('projects/fetchResource', async ({ projectId, resourceType }) => {
  const localMap:
    | { [id: string]: Worker | undefined }
    | { [id: string]: Plant | undefined } = {};
  let queryfn;
  if (resourceType === 'worker') {
    queryfn = getProjectWorkers;
  } else if (resourceType === 'plant') {
    queryfn = getProjectPlants;
  } else {
    return {
      resourceType,
      resourceMap: localMap,
    };
  }
  const run = async (nextCursor?: string) => {
    const res = await queryfn({
      projectId,
      params: { limit: 200, nextCursor },
    });

    res.data.data.forEach((r) => {
      localMap[r.id] = r;
    });

    if (res.data.paging.nextCursor) {
      await run(res.data.paging.nextCursor);
    }
  };

  await run();

  return {
    resourceType,
    resourceMap: localMap,
  };
});

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<{ list: Project[] }>) => {
      return { ...state, list: action.payload.list };
    },
    setCurrenProject: (
      state,
      action: PayloadAction<ProjectsInit['currentProject']>,
    ) => {
      return {
        ...state,
        currentProject: action.payload,
      };
    },
    setProjectRole: (state, action: PayloadAction<ProjectRole | null>) => {
      return { ...state, role: action.payload };
    },
    setProjectPolicies: (state, action: PayloadAction<Policy[]>) => {
      return { ...state, policies: action.payload };
    },
    setCardCustomLogo: (state, action: PayloadAction<CustomLogoData[]>) => {
      return { ...state, cardLogo: action.payload };
    },
    setProjectContact: (
      state,
      action: PayloadAction<ProjectsInit['projectContact']>,
    ) => {
      const newContact = [...action.payload].sort(sortByCreatedAt);
      return { ...state, projectContact: newContact };
    },
    setProjectArea: (
      state,
      action: PayloadAction<ProjectsInit['projectArea']>,
    ) => {
      return { ...state, projectArea: action.payload };
    },
    setProjectFloorPlan: (
      state,
      action: PayloadAction<ProjectsInit['projectFloorPlan']>,
    ) => {
      return { ...state, projectFloorPlan: action.payload };
    },
    setAlertWebsocket: (
      state,
      action: PayloadAction<ProjectsInit['alertWebsockets']>,
    ) => {
      return { ...state, alertWebsockets: action.payload };
    },
    setIsLoadingProjectContact: (
      state,
      action: PayloadAction<ProjectsInit['isLoadingProjectContact']>,
    ) => {
      return { ...state, isLoadingProjectContact: action.payload };
    },
    resetAll: () => initialState,
    setCurrentMembers: (
      state,
      action: PayloadAction<ProjectsInit['currentMemberMap']>,
    ) => {
      return {
        ...state,
        currentMemberMap: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResource.fulfilled, (state, { payload }) => {
      if (payload.resourceType === 'worker') {
        // @ts-ignore
        state.resource.workerMap = payload.resourceMap;
      } else if (payload.resourceType === 'plant') {
        // @ts-ignore
        state.resource.plantMap = payload.resourceMap;
      }
    });
  },
});

export const {
  setProjects,
  setCurrenProject,
  setCurrentMembers,
  setProjectRole,
  setProjectPolicies,
  setCardCustomLogo,
  setProjectContact,
  setProjectArea,
  setProjectFloorPlan,
  setIsLoadingProjectContact,
  setAlertWebsocket,
  resetAll,
} = projectSlice.actions;
export default projectSlice.reducer;
