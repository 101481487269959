import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Contact,
  DasIdEquipmentWillAssign,
  EquipmentGroup,
  Org,
} from '../../../types';
import { Daslock, Daspower, Dastemp } from '../../../types/Device';
import { Equipment, ResourceNavigator } from '../../../types/Resource';

import { navigatorEquipment } from '../../../utils/common';

type EquipmentManagementPage = {
  navigator: ResourceNavigator;
  navigatorIndex: number;
  selectedGroup: EquipmentGroup;
  daspowers: Array<Daspower>;
  dastemps: Array<Dastemp>;
  daslocks: Array<Daslock>;
  equipmentGroupProject: Array<EquipmentGroup>;
  groupEquipmentSelected: Array<EquipmentGroup>;
  connectedDevice: Array<DasIdEquipmentWillAssign>;
  equipmentGroupSelected: EquipmentGroup | undefined;
  equipmentSelected: (Equipment & { orgDetail: Org }) | undefined;
  isBasicInformationPass: boolean;
  isEquipmentGroupPass: boolean;
  isConnectedDevicePass: boolean;
  equipmentContact: Contact[];
};

const initialState: EquipmentManagementPage = {
  navigator: navigatorEquipment,
  navigatorIndex: 1,
  selectedGroup: {
    id: '',
    name: '',
    remark: '',
    projectId: '',
    createdAt: '',
    updatedAt: '',
    isDefault: false,
    sequence: null,
  },
  daspowers: [],
  dastemps: [],
  daslocks: [],
  equipmentGroupProject: [
    {
      id: '',
      name: '',
      remark: '',
      projectId: '',
      createdAt: '',
      updatedAt: '',
      isDefault: false,
      sequence: null,
    },
  ],
  equipmentSelected: {
    projectId: '',
    id: '',
    name: '',
    groupIds: [],
    voltage: 0,
    powerRating: 0,
    maxCurrent: null,
    minCurrent: null,
    maxTemperature: null,
    minTemperature: null,
    phase: 1,
    imageURL: '',
    orgId: '',
    location: null,
    bindingDaspowers: [],
    bindingDastemps: [],
    bindingDaslocks: [],
    bindingDevices: [],
    type: 'equipment',
    imageUrl: null,
    remark: '',
    createdAt: '',
    updatedAt: '',
    orgDetail: {
      id: '',
      ownerId: '',
      name: '',
      country: '',
      taxId: '',
      color: '',
      displayName: '',
      imageURL: '',
      status: 'ACTIVE',
      type: 'customer',
    },
  },
  groupEquipmentSelected: [],
  connectedDevice: [],
  equipmentGroupSelected: undefined,
  isBasicInformationPass: false,
  isEquipmentGroupPass: false,
  isConnectedDevicePass: false,
  equipmentContact: [],
};

export const equipmentManagementPageSlice = createSlice({
  name: 'equipmentManagement',
  initialState,
  reducers: {
    setSelectedEquipmentGroup: (
      state,
      action: PayloadAction<EquipmentManagementPage['selectedGroup']>,
    ) => {
      return {
        ...state,
        selectedGroup: action.payload,
      };
    },
    setDaspowersOnEquipment: (
      state,
      action: PayloadAction<EquipmentManagementPage['daspowers']>,
    ) => {
      return { ...state, daspowers: action.payload };
    },
    setDastempsOnEquipment: (
      state,
      action: PayloadAction<EquipmentManagementPage['dastemps']>,
    ) => {
      return { ...state, dastemps: action.payload };
    },
    setDaslocksOnEquipment: (
      state,
      action: PayloadAction<EquipmentManagementPage['daslocks']>,
    ) => {
      return { ...state, daslocks: action.payload };
    },
    setEquipmentGroupProject: (
      state,
      action: PayloadAction<EquipmentManagementPage['equipmentGroupProject']>,
    ) => {
      return { ...state, equipmentGroupProject: action.payload };
    },
    setEquipmentGroupSelected: (
      state,
      action: PayloadAction<EquipmentManagementPage['equipmentGroupSelected']>,
    ) => {
      return { ...state, equipmentGroupSelected: action.payload };
    },
    setGroupEquipmentSelected: (
      state,
      action: PayloadAction<EquipmentManagementPage['groupEquipmentSelected']>,
    ) => {
      return { ...state, groupEquipmentSelected: action.payload };
    },
    setEquipmentSelected: (
      state,
      action: PayloadAction<EquipmentManagementPage['equipmentSelected']>,
    ) => {
      return { ...state, equipmentSelected: action.payload };
    },
    setConnectedDevice: (
      state,
      action: PayloadAction<EquipmentManagementPage['connectedDevice']>,
    ) => {
      return { ...state, connectedDevice: action.payload };
    },
    setIsBasicInformationPass: (
      state,
      action: PayloadAction<EquipmentManagementPage['isBasicInformationPass']>,
    ) => {
      return { ...state, isBasicInformationPass: action.payload };
    },
    setIsEquipmentGroupPass: (
      state,
      action: PayloadAction<EquipmentManagementPage['isEquipmentGroupPass']>,
    ) => {
      return { ...state, isEquipmentGroupPass: action.payload };
    },
    setIsConnectedDevicePass: (
      state,
      action: PayloadAction<EquipmentManagementPage['isConnectedDevicePass']>,
    ) => {
      return { ...state, isConnectedDevicePass: action.payload };
    },
    setEquipmentContacts: (
      state,
      action: PayloadAction<EquipmentManagementPage['equipmentContact']>,
    ) => {
      return { ...state, equipmentContact: action.payload };
    },
    setNavigatorIndex: (
      state,
      action: PayloadAction<EquipmentManagementPage['navigatorIndex']>,
    ) => {
      return { ...state, navigatorIndex: action.payload };
    },
  },
});

export const {
  setSelectedEquipmentGroup,
  setDaspowersOnEquipment,
  setDastempsOnEquipment,
  setDaslocksOnEquipment,
  setEquipmentGroupProject,
  setEquipmentGroupSelected,
  setGroupEquipmentSelected,
  setEquipmentSelected,
  setConnectedDevice,
  setIsBasicInformationPass,
  setIsEquipmentGroupPass,
  setIsConnectedDevicePass,
  setEquipmentContacts,
  setNavigatorIndex,
} = equipmentManagementPageSlice.actions;
export default equipmentManagementPageSlice.reducer;
