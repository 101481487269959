import { isSameDay } from 'date-fns';

import { PlantWillAssign, PlantWithOrg } from '../../../../types';

export const checkIsFormPlantEdited = (
  plantSelected: PlantWithOrg,
  plantWillAssign: PlantWillAssign,
) => {
  const checkFieldValue = (field: keyof PlantWithOrg) => {
    const selectedValue = plantSelected[field];

    const willAssignValue = plantWillAssign[field];
    if (selectedValue === null) {
      if (willAssignValue) return true;
    } else {
      if (selectedValue !== willAssignValue) return true;
    }
    return false;
  };

  const checkFieldDate = (field: keyof PlantWithOrg) => {
    const selectedValue = plantSelected[
      field
    ] as PlantWithOrg['permitExpiryDate'];
    const willAssignValue = plantWillAssign[
      field
    ] as PlantWillAssign['permitExpiryDate'];
    if (selectedValue === null) {
      if (willAssignValue) return true;
    } else {
      if (!isSameDay(new Date(selectedValue), new Date(willAssignValue))) {
        return true;
      }
    }
    return false;
  };

  if (checkFieldValue('orgId')) return true;
  if (checkFieldValue('name')) return true;
  if (checkFieldValue('model')) return true;
  if (checkFieldValue('serialNumber')) return true;
  if (checkFieldValue('permitNumber')) return true;
  if (checkFieldValue('permitIssuer')) return true;
  if (checkFieldValue('yearOfProduction')) return true;
  if (checkFieldValue('brand')) return true;
  if (checkFieldValue('numberReading')) return true;
  if (checkFieldValue('remark')) return true;
  if (checkFieldDate('permitExpiryDate')) return true;
  if (checkFieldValue('safetyLoad')) return true;
  if (checkFieldValue('subContractorName')) return true;
  return false;
};

export const checkPlantBasicInformationAvalible = (
  basicInfomation?: PlantWillAssign,
) => {
  if (!basicInfomation?.orgId) return false;
  if (!basicInfomation?.name) return false;
  if (!basicInfomation?.model) return false;
  if (!basicInfomation?.serialNumber) return false;
  if (!basicInfomation?.permitNumber) return false;
  if (!basicInfomation?.permitIssuer) return false;
  if (!basicInfomation?.permitExpiryDate) return false;
  return true;
};
