import {
  BasicResponse,
  Coordinates,
  Group,
  PagedResponse,
  ResourceType,
} from '../types';
import { AiAlert, RequestAlertsParams } from '../types/Alert';
import {
  Asset,
  CreateAssetBasicInformation,
  CreateResourceGroupPayload,
  CreateStructureBasicInformation,
  Resource,
  Structure,
} from '../types/Resource';

import DsmAxios from './DsmAxios';

export const createResourceGroup = (data: CreateResourceGroupPayload) => {
  return DsmAxios.post<BasicResponse<Group>>(
    `/v1/projects/${data.projectId}/resources/${data.resourceType}/groups`,
    data.data,
  );
};

interface CreateResourcePayload {
  projectId: string;
  resourceType: ResourceType;
  data: Pick<Resource, 'name' | 'groupIds'> & {
    [propName: string]: any;
  };
}

interface CreateStructurePayload {
  projectId: string;
  resourceType: ResourceType;
  payload: CreateStructureBasicInformation;
}

interface CreateAssetPayload {
  projectId: string;
  resourceType: ResourceType;
  payload: CreateAssetBasicInformation;
}

export const createResource = (data: CreateResourcePayload) => {
  return DsmAxios.post<BasicResponse<Resource>>(
    `/v1/projects/${data.projectId}/resources/${data.resourceType}`,
    data.data,
  );
};

export const createResourceBase = <
  T extends Structure | Asset,
  T2 extends CreateStructurePayload | CreateAssetPayload,
>(
  data: T2,
) => {
  return DsmAxios.post<BasicResponse<T>>(
    `/v1/projects/${data.projectId}/resources/${data.resourceType}`,
    data.payload,
  );
};

interface GetProjectOfResources {
  projectId: string;
  resourceType: ResourceType;
  params?: {
    id?: string;
    prevCursor?: string | null;
    nextCursor?: string | null;
  };
}

export const getProjectOfResources = <T extends Resource | Structure | Asset>(
  data: GetProjectOfResources,
) => {
  return DsmAxios.get<PagedResponse<T>>(
    `/v1/projects/${data.projectId}/resources/${data.resourceType}`,
    {
      params: data.params,
    },
  );
};

interface UpdateResourcePayload {
  projectId: string;
  resourceId: string;
  resourceType: ResourceType;
  payload: any;
}

export const updateResource = (data: UpdateResourcePayload) => {
  return DsmAxios.patch<BasicResponse<Group>>(
    `/v1/projects/${data.projectId}/resources/${data.resourceType}/${data.resourceId}`,
    data.payload,
  );
};

interface UpdateResourceGroupPayload {
  projectId: string;
  resourceType: ResourceType;
  groupId: string;
  payload: {
    name?: string;
    remark?: string;
    buildingGroupId?: string;
    threeDimensionalAssetsId?: string;
  };
}

export const updateResourceGroup = (data: UpdateResourceGroupPayload) => {
  return DsmAxios.patch<BasicResponse<Resource>>(
    `/v1/projects/${data.projectId}/resources/${data.resourceType}/groups/${data.groupId}`,
    data.payload,
  );
};

interface SetResourceGroupsPayload {
  projectId: string;
  resourceType: ResourceType;
  resourceId;
  payload: {
    groupIds: string[];
  };
}

export const setResourceGroups = (data: SetResourceGroupsPayload) => {
  return DsmAxios.put<any>(
    `v1/projects/${data.projectId}/resources/${data.resourceType}/${data.resourceId}/groups`,
    data.payload,
  );
};

interface UploadResourceAvatarPayload {
  projectId: string;
  resourceType: ResourceType;
  resourceId;
  imageFile: File;
}

export const uploadResourceAvatar = (data: UploadResourceAvatarPayload) => {
  const formData = new FormData();
  formData.append('image', data.imageFile);
  return DsmAxios.post<any>(
    `v1/projects/${data.projectId}/resources/${data.resourceType}/${data.resourceId}/avatar`,
    formData,
  );
};

interface GetProjectGroupsPayload {
  projectId: string;
  resourceType: ResourceType;
  params?: Partial<{
    limit: number;
    id: string;
    prevCursor: string | null;
    nextCursor: string | null;
  }>;
}

export const getProjectGroups = (data: GetProjectGroupsPayload) => {
  return DsmAxios.get<PagedResponse<Group>>(
    `/v1/projects/${data.projectId}/resources/${data.resourceType}/groups`,
    {
      params: data.params,
    },
  );
};

interface GetGroupOfResourcesPayload {
  projectId: string;
  resourceType: ResourceType;
  groupId: string;
  params?: Partial<{
    name?: string;
    dasId?: string;
    limit?: number;
    prevCursor: string | null;
    nextCursor: string | null;
  }>;
}

export const getGroupOfResources = (data: GetGroupOfResourcesPayload) => {
  return DsmAxios.get<PagedResponse<Resource>>(
    `/v1/projects/${data.projectId}/resources/${data.resourceType}/groups/${data.groupId}`,
    {
      params: data.params,
    },
  );
};

interface SetGroupResourcesPayload {
  projectId: string;
  resourceType: ResourceType;
  groupId: string;
  payload: {
    [ids: string]: string[];
  };
}

export const setGroupResources = (data: SetGroupResourcesPayload) => {
  return DsmAxios.put<PagedResponse<Resource>>(
    `/v1/projects/${data.projectId}/resources/${data.resourceType}/groups/${data.groupId}`,
    data.payload,
  );
};

export const uploadResourceGroupAvatar = ({
  projectId,
  groupId,
  resourceType,
  imageFile,
}: {
  projectId: string;
  groupId: string;
  resourceType: ResourceType;
  imageFile: File;
}) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  return DsmAxios.post(
    `v1/projects/${projectId}/resources/${resourceType}/groups/${groupId}/avatar`,
    formData,
  );
};

type UnbindDeviceParam = {
  projectId: string;
  dasId: string;
  resourceType: ResourceType;
  data: {
    resourceId: string;
  };
};

export const unbindDeviceFromResource = ({
  projectId,
  dasId,
  resourceType,
  data,
}: UnbindDeviceParam) => {
  return DsmAxios.delete<any>(
    `v1/projects/${projectId}/resources/${resourceType}/bindings/${dasId}`,
    {
      data,
    },
  );
};

interface BindDeviceToResourceParam {
  projectId: string;
  dasId: string;
  resourceType: ResourceType;
  data: {
    resourceId: string;
    location?: Coordinates | null;
  };
}

export const bindDeviceToResource = ({
  projectId,
  resourceType,
  dasId,
  data,
}: BindDeviceToResourceParam) => {
  return DsmAxios.put<any>(
    `v1/projects/${projectId}/resources/${resourceType}/bindings/${dasId}`,
    data,
  );
};

interface DeleteResourceParam {
  projectId: string;
  resourceId: string;
  resourceType: ResourceType;
}

export const deleteResource = ({
  projectId,
  resourceId,
  resourceType,
}: DeleteResourceParam) => {
  return DsmAxios.delete(
    `v1/projects/${projectId}/resources/${resourceType}/${resourceId}`,
  );
};

export const getProjectAsset = async (args: {
  projectId: string;
  params?: any;
  resourceType?: ResourceType;
}) => {
  return getProjectOfResources<Asset>({
    projectId: args.projectId,
    resourceType: 'asset',
    params: args.params,
  });
};

interface GetResourceAlertPayload {
  projectId: string;
  resourceType: ResourceType;
  params: RequestAlertsParams;
}

export const getResourceAlerts = async (payload: GetResourceAlertPayload) => {
  return DsmAxios.get<PagedResponse<AiAlert>>(
    `/v1/projects/${payload.projectId}/resources/${payload.resourceType}/resource-alert`,
    {
      params: payload.params,
    },
  );
};

export const createResourceQRCode = ({
  projectId,
  resourceType,
  data,
}: {
  projectId: string;
  resourceType: ResourceType;
  data: {
    resourceIds: string[];
    host: string;
  };
}) => {
  return DsmAxios.post<BasicResponse<string[]>>(
    `v1/projects/${projectId}/resources/${resourceType}/qr-code-path`,
    data,
  );
};
