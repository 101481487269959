import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { PagedResponse, ResourceType } from '../../../types';
import { Resource } from '../../../types/Resource';

import { getGroupOfWorkers } from '../../../apis/DasloopApi';
import { getGroupOfPlant } from '../../../apis/DastrackApi';
import { getGroupOfResources } from '../../../apis/ResourceApi';

type QueryFunctionArgs = {
  projectId: string;
  groupId: string;
  resourceType?: ResourceType;
  params?: {
    name?: string;
    dasId?: string;
    limit?: number;
    nextCursor?: string;
    plantType?: 'plant' | 'crane';
  };
};

type UseGetGroupResourcesArgs<T> = {
  projectId: string | undefined;
  groupId: string | undefined | null;
  resourceType: T;
  filterText?: string;
};

const useGetGroupResourcePages = <T extends ResourceType>({
  projectId,
  groupId,
  resourceType,
  filterText,
}: UseGetGroupResourcesArgs<T>) => {
  return useInfiniteQuery({
    queryKey: [
      'get-group-resources',
      projectId,
      groupId,
      resourceType,
      filterText,
    ],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      let queryFn: (
        args: QueryFunctionArgs,
      ) => Promise<AxiosResponse<PagedResponse<Resource>, any>>;

      let filterfrom: 'name' | 'dasId';

      if (
        /[A-Z]{3,7}|[0-9]{3,6}|([A-Z]{3,7})([0-9]{3,6})?/g.test(
          filterText ?? '',
        )
      ) {
        filterfrom = 'dasId';
      } else {
        filterfrom = 'name';
      }

      if (resourceType === 'worker') {
        queryFn = getGroupOfWorkers;
      } else if (resourceType === 'plant') {
        queryFn = getGroupOfPlant;
      } else if (resourceType === 'crane') {
        queryFn = getGroupOfPlant;
      } else if (
        resourceType === 'ai' ||
        resourceType === 'environment' ||
        resourceType === 'structure'
      ) {
        // @ts-ignore
        queryFn = getGroupOfResources;
      } else {
        throw new Error(`${resourceType} no support`);
      }

      const res = await queryFn({
        projectId: projectId as string,
        groupId: groupId as string,
        resourceType: resourceType !== 'structure' ? resourceType : 'field',
        params: {
          limit: 20,
          nextCursor: pageParam,
          [filterfrom]: filterText ? filterText : undefined,
          plantType:
            resourceType === 'plant' || resourceType === 'crane'
              ? resourceType
              : undefined,
        },
      });
      return res;
    },

    enabled: !!projectId && !!groupId,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) =>
      lastPage?.data.paging?.nextCursor || undefined,
    initialPageParam: undefined,
  });
};

export default useGetGroupResourcePages;
