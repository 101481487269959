import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  Box,
  Button as MuiButton,
  ClickAwayListener,
  Popper,
  styled,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { DeviceType, Group, ResourceType, StructureAssign } from '../../types';
import { Dascas, Device } from '../../types/Device';
import {
  Ai,
  Equipment,
  Pipe,
  Plant,
  Resource,
  Structure,
  Vehicle,
  Worker,
} from '../../types/Resource';

import { updateWorkerGroup } from '../../apis/DasloopApi';
import { updatePlantGroup } from '../../apis/DastrackApi';
import { deleteResource, updateResourceGroup } from '../../apis/ResourceApi';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useIdDascasMap } from '../../hooks/devices/useIdDascasMap';
import { useProjectDasAiBoxMap } from '../../hooks/devices/useProjectDasAiboxMap';
import { useProjectDasConcreteMap } from '../../hooks/devices/useProjectDasconcreteMap';
import useGetGroupResourcePages from '../../hooks/resources/groups/useGetGroupResourcePages';
import useHasPolicy from '../../hooks/useHasPolicy';
import { fetchResource } from '../../slices/projectSlice';

import aiDefaultPng from '../../assets/images/default-image/ai_default.png';
import envDefaultPng from '../../assets/images/default-image/environment_default.png';
import equipmentDefaultPng from '../../assets/images/default-image/equipment_default.png';
import pipeDefaultPng from '../../assets/images/default-image/pipe_default.png';
import planDefaultPng from '../../assets/images/default-image/plant_default.png';
import craneDefaultPng from '../../assets/images/default-image/tower_crane_default.png';
import vehicleDefaultPng from '../../assets/images/default-image/vehicle_default.png';
import workerDefaultPng from '../../assets/images/default-image/worker_default.png';
import {
  handleAddIcon,
  handleDefaultStructureImage,
} from '../../assets/SvgIcon/HandleIcon';
import MoreSvgIcon from '../../assets/SvgIcon/MoreSvgIcon';
import RenameSvgIcon from '../../assets/SvgIcon/RenameSvgIcon';
import WorkerSvgIcon from '../../assets/SvgIcon/WorkerSvgIcon';
import MuiTheme from '../../theme';
import { debounce } from '../../utils/common';
import EmptyDataWarning from '../EmptyDataWarning';
import Loading from '../Loading';
import ProjectPanel from '../Panel/ProjectPanel';
import Search from '../Search';

import EditResource from './edit/EditResource';
import RenameDialog from './group-dialog/RenameDialog';
import { createStructure } from './TargetOperationDialog/resourceOperations';
import ViewResource from './view/ViewResource';
import ResourceItem from './ResourceItem';
import TargetOperationDialog from './TargetOperationDialog';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

const MoreContainer = styled('div')`
  cursor: pointer;
`;

const ContainerPopper = styled(Box)`
  width: 10.5rem;
  /* height: 6rem; */
  border-radius: 4px;
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  overflow: hidden;
`;

interface ContainerButtonPopperProps {
  disabled?: boolean;
}

const ContainerButtonPopper = styled('div')<ContainerButtonPopperProps>`
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary.$60};
  background-color: ${({ theme }) => theme.externalColor.dark.$15};
  pointer-events: ${({ disabled }) => (disabled === true ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled === true ? '0.3' : '1')};
  transition: 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.externalColor.dark.$20};
    color: ${({ theme }) => theme.color.secondary.$40};
  }
`;

const TextPopper = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: 'inherit',
  marginLeft: '2px',
  transition: '0.2s',
}));

const Button = styled(MuiButton)`
  &.Mui-disabled {
    opacity: 0.3;
    color: ${({ theme }) => theme.color.secondary.$60};
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.secondary.$60};
  }
`;

interface GroupOfResourceListProps {
  resourceType: ResourceType;
  selectedGroup: Group | undefined;
  groups: Array<Group> | undefined;
  refetchGroup: () => void;
  onSuccessBind?: () => void;
}

const GroupOfResourceList: FC<GroupOfResourceListProps> = ({
  resourceType,
  selectedGroup,
  groups = [],
  refetchGroup,
  onSuccessBind,
}) => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const projectContact = useAppSelector(
    (store) => store.projects.projectContact,
  );
  const { t } = useTranslation('project-setting');
  const anchorRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const groupIdSelected =
    searchParams.get('groupId') || searchParams.get('fieldId');
  const selectedResourceId = searchParams.get('resourceId');
  const [filterText, setFilterText] = useState('');
  const [showAddResourceDialog, setShowAddResourceDialog] = useState(false);
  const [showUpdateResourceGroup, setShowUpdateResourceGroup] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState<Resource>();
  const [showViewWResourceDialog, setShowViewWResourceDialog] = useState(false);
  const [showEditResourceDialog, setShowEditResourceDialog] = useState(false);
  const [navigatorIndex, setNavigatorIndex] = useState(1);
  const [totalResources, setTotalResources] = useState(0);
  const queryClient = useQueryClient();

  const {
    data: resourcePages,
    hasNextPage,
    refetch,
    fetchNextPage,
  } = useGetGroupResourcePages({
    projectId,
    groupId: groupIdSelected,
    resourceType,
    filterText,
  });

  let operationApi;
  if (resourceType === 'worker') {
    operationApi = updateWorkerGroup;
  } else if (resourceType === 'environment' || resourceType === 'field') {
    operationApi = updateResourceGroup;
  } else if (resourceType === 'plant' || resourceType === 'crane') {
    operationApi = updatePlantGroup;
  }

  const debouncedSetFilterText = useCallback(debounce(setFilterText, 800), []);

  const resourceContacts = useMemo(() => {
    if (projectContact.length > 0 && selectedResource) {
      let contactType: ResourceType = 'worker';
      if (resourceType === 'crane') {
        contactType = 'plant';
      } else if (resourceType === 'structure') {
        contactType = 'field';
      } else {
        contactType = resourceType;
      }

      return projectContact.filter((contact) =>
        contact.bindingProjectReference.some(
          (ref) =>
            ref.type === contactType && ref.refId === selectedResource.id,
        ),
      );
    }
    return [];
  }, [projectContact, selectedResource, resourceType]);

  const { data: indexByDasIdDascasMap, isLoading: isDascasMapLoading } =
    useIdDascasMap<Dascas>({
      projectId,
      queryKey: ['get-project-of-dascases', projectId],
      indexBy: 'dasId',
      enabled: resourceType === 'crane',
      type: 'dascas',
      refetchOnWindowFocus: false,
    });

  const { data: indexByDasIdDasconcreteMap, isLoading: isLoadingDasconcrete } =
    useProjectDasConcreteMap({
      projectId,
      indexBy: 'dasId',
      queryKey: ['get-project-of-dasconcretes', projectId],
      enabled: resourceType === 'structure',
      refetchOnWindowFocus: false,
    });

  const { data: indexByDasIdDasAiBoxMap, isLoading: isLoadingDasAiBox } =
    useProjectDasAiBoxMap({
      projectId,
      indexBy: 'dasId',
      queryKey: ['get-project-of-dasaiboxes', projectId],
      options: {
        enabled: resourceType === 'ai',
        refetchOnWindowFocus: false,
      },
    });

  const assignedDevices = useMemo(() => {
    if (resourceType === 'crane' && selectedResource) {
      return (selectedResource as Plant).bindingDascases.map<{
        type: DeviceType;
        device: Device;
      }>((dasId) => {
        return {
          type: 'dascas',
          device: indexByDasIdDascasMap[dasId] as Device,
        };
      });
    } else if (resourceType === 'structure' && selectedResource) {
      return (selectedResource as Structure).bindingDevices.map<{
        type: DeviceType;
        device: Device;
      }>((dasId) => {
        return {
          type: 'dasconcrete',
          device: indexByDasIdDasconcreteMap[dasId] as Device,
        };
      });
    } else if (resourceType === 'ai' && selectedResource) {
      return (selectedResource as Ai).bindingDevices.map<{
        type: DeviceType;
        device: Device;
      }>((dasId) => {
        return {
          type: 'das_ai_box',
          device: indexByDasIdDasAiBoxMap[dasId] as Device,
        };
      });
    }
    return [];
  }, [
    selectedResource,
    indexByDasIdDasconcreteMap,
    indexByDasIdDascasMap,
    indexByDasIdDasAiBoxMap,
    resourceType,
  ]);

  const { role, policies } = useAppSelector((store) => store.projects);

  const hasBindDevicePolicy = useHasPolicy(
    policies,
    'WriteDeviceBinding',
    role,
  );

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setIsOpen(false);
  };

  const handleRenameGroup = () => {
    setShowUpdateResourceGroup(true);
  };

  const handleEditGroup = () => {
    navigate(`edit-group-list?groupId=${groupIdSelected}`);
  };

  const handleSuccess = () => {
    refetch();
    setSelectedResource(undefined);
    if (projectId && resourceType === 'worker') {
      dispatch(fetchResource({ projectId, resourceType }));
    }
  };

  const editableResource = () => {
    let isAuthorizedOneGroup: boolean = false;
    let isGroupAuthorized: boolean = false;
    if (resourceType === 'worker') {
      isAuthorizedOneGroup = policies.some(
        (policy) => policy.name === 'WriteOneWorkerGroup',
      );
      isGroupAuthorized = policies
        .filter((policy) => policy.name === 'WriteOneWorkerGroup')
        .some((policy) => policy.groupId === selectedGroup?.id);
    } else if (resourceType === 'plant') {
      isAuthorizedOneGroup = policies.some(
        (policy) => policy.name === 'WriteOnePlantGroup',
      );
      isGroupAuthorized = policies
        .filter((policy) => policy.name === 'WriteOnePlantGroup')
        .some((policy) => policy.groupId === selectedGroup?.id);
    } else if (resourceType === 'structure' || resourceType === 'field') {
      isAuthorizedOneGroup = policies.some(
        (policy) => policy.name === 'WriteOneFieldGroup',
      );
      isGroupAuthorized = policies
        .filter((policy) => policy.name === 'WriteOneFieldGroup')
        .some((policy) => policy.groupId === selectedGroup?.id);
    }

    if (role === 'owner' || role === 'admin') {
      return true;
    } else if (isAuthorizedOneGroup && isGroupAuthorized) {
      return true;
    } else {
      return false;
    }
  };

  const resources = useMemo(() => {
    if (resourcePages && resourcePages?.pages.length > 0) {
      setTotalResources(resourcePages.pages[0].data.metadata.total);
    }
    return resourcePages?.pages.map((page) => page.data.data).flat() ?? [];
  }, [resourcePages]);

  useEffect(() => {
    const lastItemDom = document.querySelector('#last-item');
    if (listRef.current && lastItemDom && hasNextPage) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            fetchNextPage?.();
          }
        },
        {
          root: listRef.current,
        },
      );

      observer.observe(lastItemDom);

      return () => {
        observer.disconnect();
      };
    }
  }, [resources, hasNextPage]);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOnCloseDialog = (previousDialog: 'view' | 'edit') => {
    if (previousDialog === 'view') {
      setShowEditResourceDialog(false);
      setShowViewWResourceDialog(false);
    } else if (previousDialog === 'edit') {
      setShowEditResourceDialog(false);
      setShowViewWResourceDialog(true);
    }
  };

  const handleOnSelectNavigatorIndex = (index: number) => {
    setNavigatorIndex(index);
  };

  useEffect(() => {
    if (
      groupIdSelected &&
      selectedResourceId &&
      selectedResource?.id !== selectedResourceId &&
      resources.length > 0
    ) {
      const selected = resources.find((item) => item.id === selectedResourceId);

      setSelectedResource(selected);
      setShowViewWResourceDialog(true);
    }
  }, [groupIdSelected, resources, selectedResourceId, selectedResource]);

  const handleDeleteResource = async (resourceId: string) => {
    try {
      await deleteResource({
        projectId: projectId as string,
        resourceId,
        resourceType: resourceType === 'structure' ? 'field' : 'field',
      });

      refetch();
      onSuccessBind?.();
    } catch (error) {
      if (error instanceof Error) {
        //
      }
    }
  };

  const handleDuplicateResource = async (
    dataWillAssign: StructureAssign,
    contactIds: string[],
  ) => {
    try {
      await createStructure({
        projectId: projectId as string,
        basicInformation: { ...dataWillAssign },
        contactIds: contactIds,
        couldBindDevice: hasBindDevicePolicy,
        devices: [],
      });
      refetch();
      onSuccessBind?.();
      queryClient.invalidateQueries({
        queryKey: ['project-contacts', projectId as string],
      });
    } catch (error) {
      if (error instanceof Error) {
        //
      }
    }
  };

  const generateUniqueCopyName = (originalName: string): string => {
    let copyNumber = 1;

    const findUniqueName = () => {
      const copiedResourceName = `${originalName} (copy ${copyNumber})`;

      if (resources.some((item) => item.name === copiedResourceName)) {
        copyNumber++;
        return findUniqueName();
      }

      return copiedResourceName;
    };

    return findUniqueName();
  };

  const itemComponents = useMemo(() => {
    return resources.map((item, index) => {
      const resource = resources[index];
      const org = orgMap[resource.orgId];
      let dasIds: string[] = [];
      let defaultImage: string | undefined;
      let structure: Structure | undefined;

      if (resourceType === 'worker') {
        dasIds = dasIds = (resource as Worker).bindingDasloops.concat(
          (resource as Worker).bindingDasCollisionTags,
        );
        defaultImage = workerDefaultPng;
      } else if (resourceType === 'plant') {
        dasIds = dasIds = (resource as Plant).bindingDastracks;
        defaultImage = planDefaultPng;
      } else if (resourceType === 'equipment') {
        const localResource = resource as Equipment;
        dasIds = localResource.bindingDaspowers
          .concat(localResource.bindingDastemps)
          .concat(localResource.bindingDaslocks);
        defaultImage = equipmentDefaultPng;
      } else if (resourceType === 'vehicle') {
        dasIds = (resource as Vehicle).bindingDastrackVs;
        defaultImage = vehicleDefaultPng;
      } else if (resourceType === 'pipe') {
        dasIds = (resource as Pipe).bindingDaswaters;
        defaultImage = pipeDefaultPng;
      } else if (resourceType === 'environment') {
        defaultImage = envDefaultPng;
      } else if (resourceType === 'crane') {
        dasIds = (resource as Plant).bindingDascases;
        defaultImage = craneDefaultPng;
      } else if (resourceType === 'structure') {
        structure = resource as Structure;
        defaultImage = handleDefaultStructureImage(structure.fieldType);
      } else if (resourceType === 'ai') {
        dasIds = resource.bindingDevices;
        defaultImage = aiDefaultPng;
      }

      return (
        <ResourceItem
          id={index === resources.length - 1 ? 'last-item' : undefined}
          key={`resource-${resourceType}-item-${resource.id}`}
          name={resource.name}
          dasIds={dasIds}
          orgName={org?.name ?? ''}
          orgColor={org?.color}
          defaultImage={defaultImage}
          imageURL={resource.imageURL}
          onClick={() => {
            setSelectedResource(resource);
            setShowViewWResourceDialog(true);

            if (groupIdSelected) {
              if (resourceType === 'worker') {
                setSearchParams({
                  groupId: groupIdSelected,
                  workerId: resource.id,
                });
              } else if (resourceType === 'crane') {
                setSearchParams({
                  groupId: groupIdSelected,
                  towerId: resource.id,
                });
              }
            }
          }}
          onClickEdit={() => {
            setSelectedResource(resource);
            setShowEditResourceDialog(true);
          }}
          onClickDelete={() => {
            handleDeleteResource(resource.id);
          }}
          onClickDuplicate={() => {
            const {
              name,
              groupIds,
              fieldType,
              materialType,
              size,
              deviceCad,
              deviceCadPosition,
              strengthTarget,
              location,
              fc28,
              settingAngle,
              alertAngle,
              orgId,
              remark,
              bindingDevicesLocation,
            } = resource as Structure;
            const copiedResourceName = generateUniqueCopyName(name);

            handleDuplicateResource(
              {
                name: copiedResourceName,
                groupIds,
                fieldType,
                materialType,
                size,
                deviceCad,
                deviceCadPosition,
                strengthTarget,
                location,
                fc28,
                settingAngle,
                alertAngle,
                orgId,
                remark,
                bindingDevicesLocation,
              },
              resourceContacts.map((contact) => contact.id),
            );
          }}
          disabled={!editableResource()}
          isInList={false}
          resourceType={resourceType}
          fieldType={structure?.fieldType}
          deviceTotal={t('count-device', {
            count: structure?.bindingDevices.length,
          })}
        />
      );
    });
  }, [resources, resourceType, t, resourceContacts]);

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 87px)',
        marginRight: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={selectedGroup?.name}
      subtitle={t(`project-setting:page.devices.subtitle.${resourceType}`, {
        count: totalResources,
      })}
      rightNode={
        resourceType !== 'structure' && (
          <>
            <MoreContainer
              onClick={handleToggle}
              ref={anchorRef}
              data-cy={`btn-more-edit-${resourceType}-list`}
            >
              <MoreSvgIcon />
            </MoreContainer>
            <ClickAwayListener onClickAway={handleClose}>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement="right-end"
                modifiers={[
                  {
                    name: 'offset',
                    options: {
                      offset: [45, 0],
                    },
                  },
                ]}
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
              >
                <ContainerPopper>
                  <ContainerButtonPopper
                    onClick={handleRenameGroup}
                    disabled={!editableResource()}
                    data-cy={`btn-rename-group-${resourceType}`}
                  >
                    <RenameSvgIcon />
                    <TextPopper>{t('rename-group')}</TextPopper>
                  </ContainerButtonPopper>
                  <ContainerButtonPopper
                    onClick={handleEditGroup}
                    disabled={!editableResource()}
                    data-cy={`btn-edit-${resourceType}-list`}
                  >
                    <WorkerSvgIcon />
                    <TextPopper>{t(`edit-${resourceType}-list`)}</TextPopper>
                  </ContainerButtonPopper>
                </ContainerPopper>
              </Popper>
            </ClickAwayListener>
          </>
        )
      }
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: MuiTheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          defaultValue=""
          onChange={(e) => {
            debouncedSetFilterText(e.currentTarget.value);
          }}
        />
        <Button
          sx={{
            width: '100%',
            color: MuiTheme.color.secondary.$60,
            marginBottom: '10px',
            borderColor: MuiTheme.color.secondary.$60,
            '&:hover': {
              color: 'white',
              borderColor: 'white',
            },
          }}
          variant="outlined"
          startIcon={handleAddIcon(resourceType)}
          onClick={() => {
            setShowAddResourceDialog(true);
          }}
          disabled={!editableResource()}
          data-cy={`btn-add-${resourceType}-in-group`}
        >
          {t(`page.${resourceType}-management.add-resource`)}
        </Button>
      </div>
      <ListContainer ref={listRef}>
        {(resourceType === 'crane' && isDascasMapLoading) ||
        (resourceType === 'structure' && isLoadingDasconcrete) ||
        (resourceType === 'ai' && isLoadingDasAiBox) ? (
          <Loading />
        ) : resources.length > 0 ? (
          itemComponents
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>

      {showAddResourceDialog && (
        <TargetOperationDialog
          open
          resourceType={resourceType}
          title={t(`page.${resourceType}-management.button-more.new-resource`)}
          onCloseDialog={() => setShowAddResourceDialog(false)}
          groups={groups}
          selectedGroup={selectedGroup}
          onSuccessCreateResource={handleSuccess}
          onSuccessBind={onSuccessBind}
        />
      )}

      {showEditResourceDialog && (
        <EditResource
          projectId={projectId}
          resourceType={resourceType}
          navigatorIndex={navigatorIndex}
          selectedResource={selectedResource}
          groups={groups}
          resourceContacts={resourceContacts}
          assignedDevices={assignedDevices}
          onSelectNavigatorIndex={handleOnSelectNavigatorIndex}
          onCloseDialog={() => handleOnCloseDialog('edit')}
          onSuccess={handleSuccess}
          onSuccessBind={onSuccessBind}
        />
      )}

      {showViewWResourceDialog && (
        <ViewResource
          projectId={projectId}
          resourceType={resourceType}
          navigatorIndex={navigatorIndex}
          selectedResource={selectedResource}
          groups={groups}
          resourceContacts={resourceContacts}
          assignedDevices={assignedDevices}
          onSelectNavigatorIndex={handleOnSelectNavigatorIndex}
          onCloseDialog={() => handleOnCloseDialog('view')}
          onClickEdit={() => {
            setShowEditResourceDialog(true);
          }}
        />
      )}

      <RenameDialog
        open={showUpdateResourceGroup}
        resourceType={resourceType}
        title={t(`rename-group`)}
        submitText={t('OK')}
        name={selectedGroup?.name ?? ''}
        remark={selectedGroup?.remark ?? ''}
        groupId={selectedGroup?.id ?? ''}
        operationApi={operationApi}
        onClose={() => setShowUpdateResourceGroup(false)}
        onSuccess={() => {
          setShowUpdateResourceGroup(false);
          refetchGroup();
        }}
      />
      <Outlet />
    </ProjectPanel>
  );
};

export default GroupOfResourceList;
