import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { addDays, endOfWeek, startOfMonth, startOfWeek } from 'date-fns';

import { GetAttendanceDetailOfWorkersTimeRTO } from '../../types/index';

import { getAttendanceDetailOfWorkersTime } from '../../apis/AreaApi';

import { toISOStringWithOffset } from '../../utils/getTimeZone';

const useAttendanceOfWorkerDataTime = (
  queryKey: QueryKey,
  projectId: string,
  workerId: string,
  areaId: string,
  params: {
    refDate: Date;
    timeZone?: string;
    interval: 'hour' | 'day';
  },
  options?: Omit<
    UseQueryOptions<GetAttendanceDetailOfWorkersTimeRTO['workers']>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery({
    queryKey,
    queryFn: async () => {
      let attendanceWorkerRecords: GetAttendanceDetailOfWorkersTimeRTO['workers'] =
        {};

      for (let i = 0; i < 6; i += 1) {
        const startOfWeekDate = startOfWeek(
          addDays(startOfMonth(params.refDate), 7 * i),
        );
        const endOfWeekDate = endOfWeek(startOfWeekDate);

        const res = await getAttendanceDetailOfWorkersTime(projectId, {
          fromDate: toISOStringWithOffset(startOfWeekDate, params.timeZone),
          toDate: toISOStringWithOffset(endOfWeekDate, params.timeZone),
          workerId,
          timeZone: params.timeZone,
          interval: params.interval,
        });

        if (res.data.data.workers[workerId]) {
          const newAreas = attendanceWorkerRecords?.[workerId]?.areas ?? {};
          Object.entries(res.data.data.workers[workerId].areas).forEach(
            ([localAreaId, obj]) => {
              if (newAreas[localAreaId]) {
                newAreas[localAreaId].interval = {
                  ...newAreas[localAreaId].interval,
                  ...obj.interval,
                };
              } else {
                newAreas[localAreaId] = {
                  interval: obj.interval,
                };
              }
            },
          );
          attendanceWorkerRecords = {
            ...attendanceWorkerRecords,
            [workerId]: {
              areas: newAreas,
            },
          };
        }
      }

      return Object.entries(attendanceWorkerRecords).reduce(
        (prev, [key, value]) => {
          prev[key] = {
            areas: Object.entries(value.areas)
              .filter(([areaKey]) =>
                areaId === 'all' ? true : areaKey === areaId,
              )
              .reduce((areaPrev, [areaKey, areaValue]) => {
                areaPrev[areaKey] = areaValue;
                return areaPrev;
              }, {}),
          };
          return prev;
        },
        {} as GetAttendanceDetailOfWorkersTimeRTO['workers'],
      );
    },
    ...options,
  });
};

export default useAttendanceOfWorkerDataTime;
