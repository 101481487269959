import { BasicResponse, PagedResponse, PlantGroup, Track } from '../types';
import { Dastrack } from '../types/Device';
import { Plant } from '../types/Resource';

import DsmAxios from './DsmAxios';

export const getPlantGroups = (
  projectId: string,
  params?: {
    prevCursor?: string;
    nextCursor?: string;
    limit?: number;
    id?: string;
    orderBy?: 'sequence';
    order?: 'asc' | 'desc';
  },
) => {
  return DsmAxios.get<PagedResponse<PlantGroup>>(
    `v1/projects/${projectId}/plant-groups`,
    {
      params,
    },
  );
};

export const createPlantGroup = ({
  projectId,
  name,
  remark,
}: {
  projectId: string;
  name: string;
  remark?: string;
}) => {
  return DsmAxios.post<BasicResponse<PlantGroup>>(
    `v1/projects/${projectId}/plant-groups`,
    {
      name,
      remark,
    },
  );
};

export const updatePlantGroup = ({
  projectId,
  groupId,
  name,
  remark,
  sequence,
}: {
  projectId: string;
  groupId: string;
  name?: string;
  remark?: string;
  sequence?: number | null;
}) => {
  return DsmAxios.patch(`v1/projects/${projectId}/plant-groups/${groupId}`, {
    name,
    remark,
    sequence,
  });
};

export const getProjectPlants = (data: {
  projectId: string;
  params?: {
    prevCursor?: string;
    nextCursor?: string;
    limit?: number;
    order?: 'asc' | 'dse';
    id?: string;
    plantType?: 'plant' | 'crane';
  };
}) => {
  return DsmAxios.get<PagedResponse<Plant>>(
    `v1/projects/${data.projectId}/plants`,
    {
      params: data.params,
    },
  );
};

export const getGroupOfPlant = ({
  projectId,
  groupId,
  params,
}: {
  projectId: string;
  groupId: string;
  params?: {
    nextCursor?: string;
    plantType?: 'plant' | 'crane';
  };
}) => {
  return DsmAxios.get<PagedResponse<Plant>>(
    `v1/projects/${projectId}/plant-groups/${groupId}/plants`,
    {
      params,
    },
  );
};

export const setPlantToAGroup = ({
  projectId,
  groupId,
  plantIds,
}: {
  projectId: string;
  groupId: string;
  plantIds: Array<string>;
}) => {
  return DsmAxios.put(
    `v1/projects/${projectId}/plant-groups/${groupId}/plants`,
    {
      plantIds,
    },
  );
};

export const createPlant = (data: {
  projectId: string;
  payload: {
    name: string;
    model: string;
    plantType: 'plant' | 'crane';
    serialNumber: string;
    permitNumber: string;
    permitIssuer: string;
    permitExpiryDate: string;
    safetyLoad?: number;
    yearOfProduction?: number;
    brand?: string;
    numberReading?: number;
    remark?: string;
    groupIds: string[];
    orgId: string;
    subContractorName?: string;
  };
}) => {
  return DsmAxios.post<{ data: Plant }>(
    `v1/projects/${data.projectId}/plants`,
    data.payload,
  );
};

export const updatePlant = (data: {
  projectId: string;
  plantId: string;
  payload: {
    name: string;
    model: string;
    serialNumber: string;
    permitNumber: string;
    permitIssuer: string;
    permitExpiryDate: string;
    subContractorName?: string;
    safetyLoad: number;
    yearOfProduction: number;
    brand: string;
    numberReading: number;
    remark: string;
    orgId: string;
    plantType: 'plant' | 'crane';
    location?: {
      alt: number;
      lat: number;
      lon: number;
    };
  };
}) => {
  return DsmAxios.patch(
    `v1/projects/${data.projectId}/plants/${data.plantId}`,
    data.payload,
  );
};

export const uploadPlantAvatar = ({
  projectId,
  plantId,
  imageFile,
}: {
  projectId: string;
  plantId: string;
  imageFile: File;
}) => {
  const formData = new FormData();
  formData.append('image', imageFile);
  return DsmAxios.post(
    `v1/projects/${projectId}/plants/${plantId}/avatar`,
    formData,
  );
};

export const setPlantGroup = ({
  projectId,
  plantId,
  groupIds,
}: {
  projectId: string;
  plantId: string;
  groupIds: Array<string>;
}) => {
  return DsmAxios.put(`v1/projects/${projectId}/plants/${plantId}/groups`, {
    groupIds,
  });
};

export const getDastracksProject = (projectId: string) => {
  return DsmAxios.get<PagedResponse<Dastrack>>(
    `v1/projects/${projectId}/dastracks`,
  );
};

export const getUserDastracks = (params?: {
  id?: string;
  dasId?: string;
  projectId?: string;
  assignedProject?: boolean;
  nextCursor?: string;
  limit?: number;
}) => {
  return DsmAxios.get<PagedResponse<Dastrack>>('v1/dastracks', {
    params,
  });
};

export const getProjectDastracks = (data: {
  projectId: string;
  params?: {
    dasId?: string;
    assignedProject?: boolean;
    id?: string;
    limit?: number;
    nextCursor?: string;
    prevCursor?: string;
  };
}) => {
  return DsmAxios.get<PagedResponse<Dastrack>>(
    `v1/projects/${data.projectId}/dastracks`,
    {
      params: data.params,
    },
  );
};

export const getDastrackByDasId = (data: { dasId: string }) => {
  return DsmAxios.get<BasicResponse<Dastrack>>(`v1/dastracks/${data.dasId}`);
};

type UnbindDastrackParam = {
  projectId: string;
  dasId: string;
};

export const unbindDastrack = (obj: UnbindDastrackParam) => {
  return DsmAxios.delete(
    `v1/projects/${obj.projectId}/dastracks/${obj.dasId}/plant`,
  );
};

interface BindDastrackToPlantParam {
  projectId: string;
  dasId: string;
  data: {
    plantId: string;
  };
}

export const bindDastrackToPlant = ({
  projectId,
  dasId,
  data,
}: BindDastrackToPlantParam) => {
  return DsmAxios.put<any>(
    `v1/projects/${projectId}/dastracks/${dasId}/plant`,
    data,
  );
};

export const getPlantTracks = (
  projectId: string,
  plantId: string,
  params: {
    fromDate: string;
    toDate: string;
    limit?: number;
    order: 'desc' | 'asc';
    nextCursor?: string;
  },
) => {
  return DsmAxios.get<PagedResponse<Track>>(
    `v1/projects/${projectId}/plants/${plantId}/tracks`,
    {
      params,
    },
  );
};

export const getAllPlantsTrack = (
  projectId: string,
  params: {
    fromDate: string;
    toDate: string;
    limit?: number;
    order: 'desc' | 'asc';
    nextCursor?: string;
  },
) => {
  return DsmAxios.get<PagedResponse<Track>>(
    `v1/projects/${projectId}/plants/all/tracks`,
    {
      params,
    },
  );
};
