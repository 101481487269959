import axios, { AxiosError } from 'axios';

import { login, logout as authSliceLogout } from '../slices/authSlice';
import { resetAll as resetAllDevicesStore } from '../slices/devices/deviceSlice';
import {
  resetAll as resetAllProjectStore,
  setCurrenProject,
} from '../slices/projectSlice';
import {
  resetAll as resetAllSystemStore,
  setOrgMap,
} from '../slices/systemSlice';
import { resetAll as resetAllUserStore } from '../slices/userSlice';
import { resetAll as resetAllWebsocketStore } from '../slices/websocketSlice';

import store from '../store';

import { refresh } from './AuthAxios';

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL;
if (!BASE_API_URL) {
  throw new Error('VITE_BASE_API_URL is required');
}

export interface DsmError {
  error: {
    code: string;
    message: string;
  };
}

export const logout = () => {
  store.dispatch(authSliceLogout());
  store.dispatch(resetAllSystemStore());
  store.dispatch(resetAllProjectStore());
  store.dispatch(resetAllUserStore());
  store.dispatch(resetAllWebsocketStore());
  store.dispatch(resetAllDevicesStore());
  store.dispatch(setCurrenProject(null));
  store.dispatch(setOrgMap([]));
};

const isNeedAccessToken = (url?: string) => {
  switch (url) {
    case 'v1/forgot-password':
    case 'v1/reset-password':
      return false;
    default:
      return true;
  }
};

const DsmAxios = axios.create({
  baseURL: BASE_API_URL,
});

const needRefresh = (expiredDate?: number) => {
  const now = Date.now();

  if (!expiredDate) return false;

  return now > expiredDate;
};

const refreshAccessToken = async () => {
  const res = await refresh();
  store.dispatch(login(res.data));
  return res.data.access_token;
};

DsmAxios.interceptors.request.use(async (config) => {
  if (!isNeedAccessToken(config.url)) return config;

  const { accessToken, expiredDate } = store.getState().auth;

  let token = accessToken;

  if (needRefresh(expiredDate)) {
    try {
      token = await refreshAccessToken();
    } catch (error) {
      const err = error as Error | AxiosError<{ error_description: string }>;
      if (axios.isAxiosError(err)) {
        logout();
        return Promise.reject(
          new Error((err.response?.data as any)?.error_description),
        );
      }
      return Promise.reject(err);
    }
  }

  const newConfig = config;
  newConfig.headers['Authorization'] = `Bearer ${token}`;
  return newConfig;
});

export class DsmCustomError extends Error {
  code: string;

  constructor(code: string, message: string) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, DsmCustomError.prototype);
  }
}

DsmAxios.interceptors.response.use(
  undefined,
  async (error: Error | AxiosError<DsmError>) => {
    if (axios.isAxiosError(error)) {
      if (error.config?.url === 'v1/me') {
        logout();
      }
      const result: DsmError = (error.response?.data as DsmError) ?? {
        error: { code: 'Unknown', message: error.message },
      };

      return Promise.reject(
        new DsmCustomError(result.error.code, result.error.message),
      );
    }

    return Promise.reject(error);
  },
);

export default DsmAxios;
