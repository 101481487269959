import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Cartographic, sampleTerrain } from 'cesium';

import { getProject } from '../../apis/ProjectApi';
import { who } from '../../apis/UserApi';
import { useAppSelector } from '../../hooks';
import { fetchResource, setCurrenProject } from '../../slices/projectSlice';

import ProjectSidebar from '../../components/ProjectSidebar';

import { terrainProvider } from '../../utils/Map3dUtil';

import SuspendDialog from './SuspendDialog';

const DashboardContainer = styled('div')`
  display: flex;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(180deg, #3e3e3e 0%, #212121 100%);
  & > div {
    margin-left: 60px;
  }
`;

const HomePage = () => {
  const dispatch = useDispatch();
  const selectedProjectId = useAppSelector(
    (store) => store.system.selectedProjectId,
  );
  const orgMap = useAppSelector((store) => store.system.orgMap);

  const { data: project, isPending: isProjectLoading } = useQuery({
    queryKey: ['get-project', selectedProjectId],
    queryFn: async () => {
      const res = await getProject(selectedProjectId as string);
      return res;
    },
    enabled: !!selectedProjectId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (project) {
      const updateProjectWithElevation = async () => {
        const result = await sampleTerrain(terrainProvider, 13, [
          Cartographic.fromDegrees(project.center[0], project.center[1]),
        ]);
        dispatch(setCurrenProject({ ...project, elevation: result[0].height }));
      };
      updateProjectWithElevation();
    }
  }, [project]);

  useQuery({
    queryKey: ['get-all-workers', selectedProjectId],
    queryFn: async () => {
      if (selectedProjectId) {
        dispatch(
          // @ts-ignore
          fetchResource({
            projectId: selectedProjectId,
            resourceType: 'worker',
          }),
        );
      }
      return null;
    },
    enabled: !!selectedProjectId,
    refetchOnWindowFocus: false,
    refetchInterval: 600000,
  });

  const status = orgMap[project?.orgId ?? '']?.status ?? 'ACTIVE';

  const { data: owner } = useQuery({
    queryKey: ['get-who', `ownerId=${project?.ownerId}`],
    queryFn: async () => {
      const res = await who({ id: project?.ownerId });
      return res.data.data;
    },
    enabled: !!project?.ownerId,
    refetchOnWindowFocus: false,
  });

  return (
    <DashboardContainer className="DashboardContainer">
      {!isProjectLoading && (
        <>
          {status === 'ACTIVE' && (
            <>
              <ProjectSidebar />
              <Outlet />
            </>
          )}
          <SuspendDialog open={status === 'SUSPENDED'} owner={owner} />
        </>
      )}
    </DashboardContainer>
  );
};

export default HomePage;
