import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { EquipmentGroup, EquipmentWithOrg, Group, Org } from '../../../types';

import { getProjectDaslocks } from '../../../apis/DaslockApi';
import {
  createEquipmentGroup,
  getEquipmentGroups,
  getGroupOfEquipment,
  getProjectDaspowers,
} from '../../../apis/DaspowerApi';
import { getProjectDastemps } from '../../../apis/DastempApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  setDaslocksOnEquipment,
  setDaspowersOnEquipment,
  setDastempsOnEquipment,
  setSelectedEquipmentGroup,
} from '../../../slices/pages/project-setting/equipmentManagementPageSlice';

import { groupCompareFn } from '../../../utils/Sort';
import ManagementGroupList from '../management/ManagementGroupList';

import EquipmentGroupEquipmentList from './EquipmentGroupEquipmentList';

const Container = styled('div', { label: 'WorkerManagementPage-Container' })`
  display: flex;
  height: calc(100vh - 87px);
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const EmptyOrg: Org = {
  id: '',
  ownerId: '',
  name: '',
  country: '',
  taxId: '',
  color: '',
  displayName: '',
  type: 'customer',
  imageURL: null,
  status: 'ACTIVE',
};

const EquipmentManagementPage = () => {
  const { projectId } = useParams();
  const { i18n } = useTranslation();
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { role, policies } = useAppSelector((store) => store.projects);
  const [searchParam, setSearchParam] = useSearchParams();
  const equipmentGroupIdSelected = searchParam.get('groupId');

  const {
    data: equipmentGroups,
    refetch: refetchEquipmentGroup,
    isFetching: isLoadingEquipmentGroup,
  } = useQuery({
    queryKey: ['get-equipment-groups', projectId, i18n.language],
    queryFn: async () => {
      const res = await getEquipmentGroups(projectId as string);
      return res.data.data.sort(
        groupCompareFn.bind({ locales: i18n.language }),
      );
    },
    enabled: !!projectId,
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const selectedEquipmentGroup: EquipmentGroup | undefined = useMemo(() => {
    return equipmentGroups.find(
      (group) => group.id === equipmentGroupIdSelected,
    );
  }, [equipmentGroupIdSelected, equipmentGroups]);

  const { data: groupOfEquipments, isFetching: isLoadingEquipmentOfAProject } =
    useQuery({
      queryKey: ['get-equipment-of-a-group', selectedEquipmentGroup?.id],
      queryFn: async () => {
        const res = await getGroupOfEquipment({
          projectId: projectId as string,
          groupId: (selectedEquipmentGroup as Group).id,
        });
        return res?.data.data
          .map<EquipmentWithOrg>((equipment) => ({
            ...equipment,
            orgDetail: orgMap[equipment.orgId] ?? EmptyOrg,
          }))
          .sort((a, b) => a.name.localeCompare(b.name, i18n.language));
      },
      enabled: !!selectedEquipmentGroup,
      initialData: [],
      refetchOnWindowFocus: false,
    });

  const { data: daspowersData, isSuccess: isDaspowersSuccess } = useQuery({
    queryKey: ['get-daspower-project', projectId],
    queryFn: async () =>
      getProjectDaspowers({ projectId: projectId as string }),
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isDaspowersSuccess && daspowersData) {
      dispatch(setDaspowersOnEquipment(daspowersData.data.data));
    }
  }, [isDaspowersSuccess, daspowersData, dispatch]);

  const { data: dastempsData, isSuccess: isDastempsSuccess } = useQuery({
    queryKey: ['get-dastemp-project', projectId],
    queryFn: async () => getProjectDastemps({ projectId: projectId as string }),
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isDastempsSuccess && dastempsData?.data.data.length > 0) {
      dispatch(setDastempsOnEquipment(dastempsData.data.data));
    }
  }, [isDastempsSuccess, dastempsData, dispatch]);

  const { data: daslocksData, isSuccess: isDaslocksSuccess } = useQuery({
    queryKey: ['get-daslock-project', projectId],
    queryFn: async () => getProjectDaslocks({ projectId: projectId as string }),
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isDaslocksSuccess && daslocksData?.data.data.length > 0) {
      dispatch(setDaslocksOnEquipment(daslocksData.data.data));
    }
  }, [isDaslocksSuccess, daslocksData, dispatch]);

  useEffect(() => {
    if (selectedEquipmentGroup) {
      dispatch(setSelectedEquipmentGroup(selectedEquipmentGroup));
    }
  }, [selectedEquipmentGroup]);

  useEffect(() => {
    if (!equipmentGroupIdSelected && equipmentGroups.length > 0) {
      navigate(
        `/project-setting/${projectId}/equipment-group-management?groupId=${equipmentGroups[0].id}`,
        { replace: true },
      );
    }
  }, [equipmentGroupIdSelected, projectId, equipmentGroups]);

  const isEditable = () => {
    const isAuthorizedCreateEquipmentGroup = policies.some(
      (policy) => policy.name === 'CreateEquipmentGroup',
    );
    return (
      role === 'owner' || role === 'admin' || isAuthorizedCreateEquipmentGroup
    );
  };

  return (
    <Container>
      <ManagementGroupList
        data={equipmentGroups}
        selectedGroup={selectedEquipmentGroup}
        onSelect={(group) =>
          setSearchParam({
            groupId: group.id,
          })
        }
        onSuccessCreateGroup={refetchEquipmentGroup}
        isLoadingGroup={isLoadingEquipmentGroup}
        handleCreateGroup={createEquipmentGroup}
        isEditable={isEditable()}
        resource="equipment"
      />
      {selectedEquipmentGroup && (
        <EquipmentGroupEquipmentList
          equipmentGroupSelected={selectedEquipmentGroup}
          data={groupOfEquipments}
          refetchGroup={refetchEquipmentGroup}
          equipmentGroupProject={equipmentGroups}
          isLoadingEquipmentOfAProject={isLoadingEquipmentOfAProject}
        />
      )}
      <Outlet />
    </Container>
  );
};

export default EquipmentManagementPage;
