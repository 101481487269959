import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar as MuiAvatar, styled, Typography } from '@mui/material';
import { format } from 'date-fns';

import { Org } from '../../../../types';
import { Plant } from '../../../../types/Resource';

import PlantDefault from '../../../../assets/images/default-image/plant_default.png';

const Container = styled('div')`
  height: calc(100% - 48px);
  width: 100%;
`;
const ContainerContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${({ theme }) => theme.color.box_bbg};
  padding: 10px;
`;

const ContentTitle = styled(Typography)`
  color: ${({ theme }) => theme.color.box_bbg};
`;

const ContainerImage = styled('div')`
  margin: 0 auto;
  width: 205px;
  position: relative;
`;

const Avatar = styled(MuiAvatar)`
  width: 205px;
  height: 205px;
  object-fit: cover;
  border-radius: 4px;
  margin: 0 auto;
`;

const ContainerDetail = styled('div')`
  display: flex;
  flex: 1;
`;

const Row = styled('div')`
  width: 50%;
`;

const Title = styled(Typography)(({ theme }) => ({
  ...theme.externalTypography.body3,
  color: theme.color.secondary.$60,
  height: '16px',
}));

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.color.box_bbg,
  height: '24px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Item = styled('div')`
  margin-top: 2px;
  height: 42px;
  width: 140px;
`;

const ItemRemark = styled('div')`
  margin-top: 8px;
  height: 42px;
  width: 300px;
`;

const ContainerRemark = styled('div')`
  height: 70px;
  margin-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.color.secondary.$80};
`;

const OrgStatus = styled('div')`
  width: 6px;
  height: 14px;
  border-radius: 3px;
`;

interface BasicInformationContentViewProps {
  data: (Plant & { orgDetail: Org | undefined }) | undefined;
}

const BasicInformationContentView: React.VFC<
  BasicInformationContentViewProps
> = ({ data }) => {
  const { t } = useTranslation('project-setting');

  return (
    <Container>
      <ContentTitle>
        {t('page.plant-management.dialog.create-plant.basic-information.title')}
      </ContentTitle>
      <ContainerContent>
        <ContainerImage>
          <Avatar
            src={data?.imageURL ?? ''}
            alt="worker photo"
            sx={{ marginBottom: '10px' }}
            variant="square"
          >
            <Avatar src={PlantDefault} alt="plant beeinventor" />
          </Avatar>
        </ContainerImage>
        <ContainerDetail>
          <Row>
            <Item>
              <Title>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.organization',
                )}
              </Title>
              <div
                style={{
                  display: 'flex',
                  height: '24px',
                  alignItems: 'center',
                }}
              >
                <OrgStatus
                  sx={{ backgroundColor: data?.orgDetail?.color ?? '#58B99E' }}
                />
                <Text title={data?.orgDetail?.name} sx={{ marginLeft: '4px' }}>
                  {data?.orgDetail?.name ?? 'N/A'}
                </Text>
              </div>
            </Item>
            <Item>
              <Title>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.model',
                )}
              </Title>
              <Text title={data?.model}>{data?.model ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title>{t('sub-contractor')}</Title>
              <Text title={data?.subContractorName}>
                {data?.subContractorName ?? 'N/A'}
              </Text>
            </Item>
            <Item>
              <Title>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.permit-number',
                )}
              </Title>
              <Text title={data?.permitNumber}>
                {data?.permitNumber ?? 'N/A'}
              </Text>
            </Item>
            <Item>
              <Title
                title={data?.safetyLoad ? data.safetyLoad.toString() : 'N/A'}
              >
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.safety-load',
                )}
              </Title>
              <Text>{data?.safetyLoad ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title title={'brand'}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.brand',
                )}
              </Title>
              <Text title={data?.brand ?? 'N/A'}>{data?.brand ?? 'N/A'}</Text>
            </Item>
          </Row>
          <Row>
            <Item>
              <Title title={data?.name}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.name',
                )}
              </Title>
              <Text>{data?.name ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title title={data?.serialNumber}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.serial-number',
                )}
              </Title>
              <Text>{data?.serialNumber ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title title={data?.permitIssuer ?? 'N/A'}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.permit-issued',
                )}
              </Title>
              <Text>{data?.permitIssuer ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title title={data?.yearOfProduction?.toString() ?? 'N/A'}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.year-of-production',
                )}
              </Title>
              <Text>{data?.yearOfProduction ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title title={data?.numberReading?.toString() ?? 'N/A'}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.number-reading',
                )}
              </Title>
              <Text>{data?.numberReading?.toString() ?? 'N/A'}</Text>
            </Item>
            <Item>
              <Title title={'expiryDate'}>
                {t(
                  'page.plant-management.dialog.create-plant.basic-information.expiry-date',
                )}
              </Title>
              <Text>
                {data?.permitExpiryDate
                  ? format(new Date(data.permitExpiryDate), 'yyyy-MM-dd')
                  : 'N/A'}
              </Text>
            </Item>
          </Row>
        </ContainerDetail>
        <ContainerRemark>
          <ItemRemark>
            <Title>{t('remark')}</Title>
            <Text title={data?.remark ? data?.remark : ''}>
              {data?.remark ?? 'N/A'}
            </Text>
          </ItemRemark>
        </ContainerRemark>
      </ContainerContent>
    </Container>
  );
};

export default BasicInformationContentView;
