import { QueryKey, useQuery } from '@tanstack/react-query';

import { DasConcrete } from '../../types/Device';

import { getProjectDasConcretes } from '../../apis/devices/DasConcreteApi';

type UseProjectDasConcreteMapParams = {
  queryKey: QueryKey;
  projectId: string | null | undefined;
  refetchInterval?: number;
  refetchOnWindowFocus?: boolean;
  enabled?: boolean;
  indexBy?: 'id' | 'dasId';
};

export type DasConcreteMap = { [id: string]: DasConcrete | undefined };

export const useProjectDasConcreteMap = ({
  queryKey,
  projectId,
  refetchInterval,
  refetchOnWindowFocus,
  enabled = true,
  indexBy = 'id',
}: UseProjectDasConcreteMapParams) => {
  return useQuery<DasConcreteMap>({
    queryKey: queryKey.concat(indexBy),
    queryFn: async () => {
      let localDevices: DasConcrete[] = [];
      const load = async (nextCursor?: string) => {
        const res = await getProjectDasConcretes({
          projectId: projectId as string,
          params: {
            nextCursor,
          },
        });
        localDevices = localDevices.concat(res.data.data);
        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };
      await load();
      return localDevices.reduce((prev, curr) => {
        const key = indexBy === 'id' ? curr.id : curr.dasId;
        prev[key] = curr;
        return prev;
      }, {} as DasConcreteMap);
    },
    initialData: {},
    enabled: !!projectId && enabled,
    refetchInterval,
    refetchOnWindowFocus,
  });
};
