import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DialogButton } from '@beeinventor/dasiot-react-component-lib';
import {
  ArrowBackIos,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import { Button as MuiButton, styled } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Org, VehicleWithOrg } from '../../../../types';

import {
  getGroupOfVehicle,
  getProjectDastrackV,
  getProjectVehicles,
  getVehicleGroups,
  setVehiclesToGroup,
} from '../../../../apis/DastrackVApi';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setDastrackV } from '../../../../slices/pages/project-setting/vehicleManagementPageSlice';

import ListOfGroup from './ListOfGroup';
import ListOfVehicleProject from './ListOfVehicleProject';

const Container = styled('div', { label: 'Member-ManagementPage-Container' })`
  display: flex;
  flex-direction: column;
`;
const Header = styled('div')`
  height: 32px;
  margin-bottom: 10px;
`;

const ContainerMain = styled('div')`
  display: flex;
  height: calc(100vh - 129px);
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ContainerButton = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled(MuiButton)`
  color: ${({ theme }) => theme.color.box_bbg};
  padding: 0%;
  margin: 0;
  min-width: 32px;
  height: 32px;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
  &.Mui-disabled {
    color: rgba(255, 255, 255, 0.1);
  }
`;

const EditVehicleGroupListManagement: React.FC = () => {
  const { mutate: mutateSetVehiclesToGroup, isPending: isLoadingSetVehicle } =
    useMutation({
      mutationFn: setVehiclesToGroup,
    });
  const { projectId } = useParams();
  const { selectedGroup } = useAppSelector((store) => store.vehicleManagement);
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isEnableRemove, setIsEnableRemove] = useState(true);
  const [isEnableSetToGroup, setIsEnableSetToGroup] = useState(true);
  const [listVehicleIdOfGroup, setListVehicleIdOfGroup] = useState<
    Array<string>
  >([]);
  const [listVehicleIdOfProject, setListVehicleIdOfProject] = useState<
    Array<string>
  >([]);

  const dispatch = useAppDispatch();

  const {
    data: vehiclesOfGroup,
    refetch: refetchVehicleOfGroup,
    isLoading: isLoadingVehicleOfGroup,
  } = useQuery({
    queryKey: [`get-group-vehicle-${selectedGroup?.id}`],
    queryFn: () =>
      getGroupOfVehicle({
        projectId: projectId as string,
        groupId: selectedGroup?.id ?? '',
      }),
    enabled: !!selectedGroup?.id,
  });

  const listVehicle: Array<VehicleWithOrg> = useMemo(() => {
    const EmptyOrg: Org = {
      id: '',
      ownerId: '',
      name: '',
      country: '',
      taxId: '',
      displayName: '',
      imageURL: '',
      status: 'ACTIVE',
      type: 'customer',
      color: '',
    };

    if (vehiclesOfGroup && vehiclesOfGroup.data.data.length > 0) {
      const vehicleOrg = vehiclesOfGroup.data.data.map((vehicle) => ({
        ...vehicle,
        orgDetail: orgMap[vehicle.orgId] ?? EmptyOrg,
      }));

      return vehicleOrg;
    }

    return [];
  }, [vehiclesOfGroup, orgMap]);

  const { data: vehicleGroup } = useQuery({
    queryKey: ['get-vehicle-group', projectId],
    queryFn: async () => {
      const res = await getVehicleGroups(projectId as string);
      return res.data.data;
    },
    enabled: !!projectId,
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const {
    data: vehicleOfProject,
    refetch: refetchVehicleOfProject,
    isLoading: isLoadingVehicleOfProject,
  } = useQuery({
    queryKey: ['get-vehicle-of-project'],
    queryFn: async () => getProjectVehicles({ projectId: projectId ?? '' }),
    enabled: !!projectId,
  });

  const { data: dastrackVs, refetch: refetchDastrackV } = useQuery({
    queryKey: ['get-dastracks-v-project'],
    queryFn: async () => getProjectDastrackV({ projectId: projectId ?? '' }),
  });

  const vehicleProject: Array<VehicleWithOrg> = useMemo(() => {
    const EmptyOrg: Org = {
      id: '',
      ownerId: '',
      name: '',
      country: '',
      taxId: '',
      displayName: '',
      imageURL: '',
      status: 'ACTIVE',
      type: 'customer',
      color: '',
    };
    if (vehicleOfProject && vehicleOfProject.data.data.length > 0) {
      return vehicleOfProject.data.data
        .filter((wp) =>
          vehiclesOfGroup?.data.data.every((wg) => wg.id !== wp.id),
        )
        .map((vehicle) => ({
          ...vehicle,
          orgDetail: orgMap[vehicle.orgId] ?? EmptyOrg,
        }));
    }
    return [];
  }, [vehicleOfProject, vehiclesOfGroup, orgMap]);

  const handleRemove = async () => {
    try {
      await mutateSetVehiclesToGroup(
        {
          projectId: projectId as string,
          groupId: selectedGroup?.id ?? '',
          vehicleIds: listVehicleIdOfGroup.concat(listVehicleIdOfProject),
        },
        {
          onSuccess: () => {
            refetchVehicleOfGroup();
            refetchVehicleOfProject();
            setIsEnableRemove(true);
            setIsEnableSetToGroup(true);
            setListVehicleIdOfGroup([]);
            setListVehicleIdOfProject([]);
          },
        },
      );
    } catch (err) {
      const error = err as Error;
      alert(error.message);
    }
  };

  useEffect(() => {
    if (dastrackVs && dastrackVs.data.data.length > 0) {
      dispatch(setDastrackV(dastrackVs?.data.data));
    }
  }, [dastrackVs]);

  useEffect(() => {
    if (listVehicleIdOfGroup.length < listVehicle.length) {
      setIsEnableRemove(false);
    } else {
      setIsEnableRemove(true);
    }
  }, [listVehicleIdOfGroup, listVehicle]);

  useEffect(() => {
    if (listVehicleIdOfProject.length > 0) {
      setIsEnableSetToGroup(false);
    } else {
      setIsEnableSetToGroup(true);
    }
  }, [listVehicleIdOfProject]);

  return (
    <Container>
      <Header>
        <DialogButton
          sx={{
            '&.Mui-disabled': {
              color: 'white',
            },
            '&:hover, &:active': {
              background: 'transparent',
            },
          }}
          color="secondary"
          onClick={() =>
            navigate(`/project-setting/${projectId}/vehicle-group-management`, {
              replace: true,
            })
          }
          startIcon={<ArrowBackIos />}
          data-cy="btn-end-edit-vehicle-list"
        >
          {t('end-editing')}
        </DialogButton>
      </Header>
      <ContainerMain>
        <ListOfGroup
          selectedIds={(wg) => setListVehicleIdOfGroup(wg)}
          data={listVehicle}
          isLoading={isLoadingVehicleOfGroup}
        />
        <ContainerButton>
          <Button
            onClick={handleRemove}
            disabled={isEnableRemove}
            data-cy="btn-remove-vehicle-to-group"
          >
            <KeyboardDoubleArrowRight />
          </Button>
          <Button
            onClick={handleRemove}
            disabled={isEnableSetToGroup}
            data-cy="btn-add-vehicle-to-group"
          >
            <KeyboardDoubleArrowLeft />
          </Button>
        </ContainerButton>
        <ListOfVehicleProject
          data={vehicleProject}
          onSuccessBind={refetchDastrackV}
          onSuccessEdit={refetchVehicleOfProject}
          selectedId={(wp) => setListVehicleIdOfProject(wp)}
          listGroupProject={vehicleGroup}
          isLoadingAssingGroup={isLoadingSetVehicle}
          isLoading={isLoadingVehicleOfProject}
        />
      </ContainerMain>
    </Container>
  );
};

export default EditVehicleGroupListManagement;
