import { useEffect, useState } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { DasAiBox } from '../../types/Device';

import { getProjectDasAiBoxes } from '../../apis/devices/DasAiBoxApi';

type UseProjectDasAiBoxMapParams = {
  queryKey: QueryKey;
  projectId: string | null | undefined;
  indexBy?: 'id' | 'dasId';
  options?: Omit<UseQueryOptions<DasAiBoxMap>, 'queryKey' | 'queryFn'>;
};

export type DasAiBoxMap = { [id: string]: DasAiBox | undefined };

export const useProjectDasAiBoxMap = ({
  queryKey,
  projectId,
  indexBy = 'id',
  options,
}: UseProjectDasAiBoxMapParams) => {
  const [deviceMap, setDeviceMap] = useState<DasAiBoxMap>({});

  const { data, ...others } = useQuery({
    queryKey: queryKey.concat(indexBy),
    queryFn: async () => {
      let localDevices: DasAiBox[] = [];

      const load = async (nextCursor?: string) => {
        const res = await getProjectDasAiBoxes({
          projectId: projectId as string,
          params: {
            nextCursor,
          },
        });
        localDevices = localDevices.concat(res.data.data);
        if (res.data.paging.nextCursor) {
          await load(res.data.paging.nextCursor);
        }
      };

      await load();

      return localDevices.reduce<DasAiBoxMap>((prev, curr) => {
        const key = indexBy === 'id' ? curr.id : curr.dasId;
        prev[key] = curr;
        return prev;
      }, {});
    },
    enabled: !!projectId,
    ...options,
  });

  useEffect(() => {
    setDeviceMap(data ?? {});
  }, [data]);

  return {
    data: deviceMap,
    ...others,
  };
};
