import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PlantGroup } from '../../../types';
import { Dastrack } from '../../../types/Device';

type PlantManagementPage = {
  selectedGroup: PlantGroup;
  dastracks: Array<Dastrack>;
};

const initialState: PlantManagementPage = {
  selectedGroup: {
    id: '',
    name: '',
    remark: '',
    projectId: '',
    createdAt: '',
    updatedAt: '',
    isDefault: false,
    sequence: null,
  },
  dastracks: [],
};

export const plantManagementPageSlice = createSlice({
  name: 'plantManagement',
  initialState,
  reducers: {
    setSelectedPlantGroup: (
      state,
      action: PayloadAction<PlantManagementPage['selectedGroup']>,
    ) => {
      return {
        ...state,
        selectedGroup: action.payload,
      };
    },
    setDastracks: (
      state,
      action: PayloadAction<PlantManagementPage['dastracks']>,
    ) => {
      return { ...state, dastracks: action.payload };
    },
  },
});

export const { setSelectedPlantGroup, setDastracks } =
  plantManagementPageSlice.actions;
export default plantManagementPageSlice.reducer;
