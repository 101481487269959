import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import {
  DasIdEquipmentWillAssign,
  EquipmentGroup,
  Org,
} from '../../../../types';
import { Equipment } from '../../../../types/Resource';

import { getEquipmentById } from '../../../../apis/DaspowerApi';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  setConnectedDevice,
  setEquipmentContacts,
  setEquipmentGroupProject,
  setEquipmentSelected,
  setGroupEquipmentSelected,
} from '../../../../slices/pages/project-setting/equipmentManagementPageSlice';

import EmptyDataWarning from '../../../../components/EmptyDataWarning';
import Loading from '../../../../components/Loading';
import ProjectPanel from '../../../../components/Panel/ProjectPanel';
import Search from '../../../../components/Search';

import MuiTheme from '../../../../theme';

import EquipmentItemPermission from './EquipmentItemPermission';

const ListContainer = styled('ul', { label: 'MemberList-ListContainer' })`
  flex: 1;
  padding: 0;
  margin: 0;
  overflow: auto;
`;

type EquipmentPermissionListProps = {
  selectedId: (equipmentIds: Array<string>) => void;
  data: Array<Equipment & { orgDetail: Org }>;
  equipmentGroupProject: Array<EquipmentGroup>;
  isLoadingSetEquipment: boolean;
  isLoadingEquipmentOfProject: boolean;
};

const EquipmentPermissionList: React.VFC<EquipmentPermissionListProps> = ({
  selectedId,
  data,
  equipmentGroupProject,
  isLoadingSetEquipment,
  isLoadingEquipmentOfProject,
}) => {
  const orgMap = useAppSelector((store) => store.system.orgMap);
  const { t, i18n } = useTranslation('project-setting');
  const [filterText, setFilterText] = useState('');
  const [equipmentIdWillAssign, setEquipmentIdWillAssign] = useState<
    Array<string>
  >([]);
  const [selectedEquipment, setSelectedEquipment] = useState<
    Equipment & { orgDetail: Org }
  >();
  const { daspowers } = useAppSelector((store) => store.equipmentManagement);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const [searchParam] = useSearchParams();
  const equipmentGroupIdSelected = searchParam.get('groupId');
  const { projectContact } = useAppSelector((store) => store.projects);

  const handleOnChange = (value: any) => {
    const newValue = value;
    const updateValue = [...equipmentIdWillAssign];
    if (equipmentIdWillAssign.includes(newValue)) {
      updateValue.splice(updateValue.indexOf(newValue), 1);
    } else {
      updateValue.push(newValue);
    }
    setEquipmentIdWillAssign(updateValue);
  };

  useEffect(() => {
    if (projectContact.length > 0 && selectedEquipment) {
      const equipmentContacts = projectContact.filter((contact) =>
        contact.bindingProjectReference.some(
          (ref) =>
            ref.type === 'equipment' && ref.refId === selectedEquipment.id,
        ),
      );

      dispatch(setEquipmentContacts(equipmentContacts));
    }
  }, [projectContact, selectedEquipment]);

  useEffect(() => {
    if (isLoadingSetEquipment) {
      setEquipmentIdWillAssign([]);
    }
  }, [isLoadingSetEquipment]);

  useEffect(() => {
    selectedId(equipmentIdWillAssign);
  }, [equipmentIdWillAssign]);

  const groupEquipmentSelected = useMemo(() => {
    return equipmentGroupProject.filter((group) =>
      selectedEquipment ? selectedEquipment.groupIds.includes(group.id) : [],
    );
  }, [selectedEquipment, equipmentGroupProject]);

  const connectedDevice: Array<DasIdEquipmentWillAssign> = useMemo(() => {
    if (daspowers.length > 0) {
      return daspowers
        .filter((daspower) =>
          selectedEquipment?.bindingDaspowers.includes(daspower.dasId),
        )
        .map((d) => ({
          id: d.id,
          name: d.dasId,
          batteryLevel:
            d.shadow.dataPoint &&
            d.shadow.dataPoint.batteryVoltage &&
            d.shadow.dataPoint.batteryVoltage.value
              ? d.shadow.dataPoint.batteryVoltage.value
              : 0,
          type: 'daspower',
        }));
    }
    return [];
  }, [selectedEquipment, daspowers]);

  const { data: dataEquipmentById } = useQuery({
    queryKey: ['get-equipment-by-id', projectId],
    queryFn: async () =>
      getEquipmentById(projectId as string, selectedEquipment?.id ?? ''),
    enabled: !!selectedEquipment,
  });

  const selectedEquipmentById: (Equipment & { orgDetail: Org }) | undefined =
    useMemo(() => {
      const EmptyOrg: Org = {
        id: '',
        ownerId: '',
        name: '',
        country: '',
        taxId: '',
        color: '',
        displayName: '',
        type: 'customer',
        imageURL: null,
        status: 'ACTIVE',
      };
      if (dataEquipmentById) {
        return {
          ...dataEquipmentById.data.data,
          orgDetail: orgMap[dataEquipmentById.data.data.orgId] ?? EmptyOrg,
        };
      }
      return;
    }, [dataEquipmentById, orgMap]);

  useEffect(() => {
    if (equipmentGroupProject) {
      dispatch(setEquipmentGroupProject(equipmentGroupProject));
    }
  }, [equipmentGroupProject]);

  useEffect(() => {
    dispatch(setGroupEquipmentSelected(groupEquipmentSelected));
  }, [groupEquipmentSelected]);

  useEffect(() => {
    dispatch(setEquipmentSelected(selectedEquipmentById));
  }, [selectedEquipmentById]);

  useEffect(() => {
    dispatch(setConnectedDevice(connectedDevice));
  }, [connectedDevice]);

  const items = data
    .filter((equipment) => equipment.name.indexOf(filterText) !== -1)
    .sort((a, b) => a.name.localeCompare(b.name, i18n.language))
    .map((equipment) => (
      <EquipmentItemPermission
        key={`Equipment ${equipment.id}`}
        equipment={equipment}
        onChange={handleOnChange}
        onClick={() => {
          setSelectedEquipment(equipment);
          navigate(`view?groupId=${equipmentGroupIdSelected}`);
        }}
      />
    ));

  return (
    <ProjectPanel
      sx={{
        height: 'calc(100vh - 129px)',
        marginLeft: '10px',
        '@media (max-width: 600px)': {
          maxWidth: '275px',
        },
        '& .content': {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 'calc(100% - 40px)',
          overflow: 'auto',
        },
      }}
      title={t('page.equipment-management.title-equipment-list-permission')}
    >
      <div style={{ padding: '0 16px' }}>
        <Search
          sx={{
            width: '100%',
            height: '32px',
            border: 'none',
            background: MuiTheme.color.secondary.$80,
            marginBottom: '10px',
            '& input': { color: 'white' },
          }}
          placeholder=""
          value={filterText}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
      </div>
      <ListContainer data-cy="container-equipment-list-permission">
        {isLoadingEquipmentOfProject ? (
          <Loading />
        ) : items.length > 0 ? (
          items
        ) : (
          <EmptyDataWarning mode="dark" />
        )}
      </ListContainer>
    </ProjectPanel>
  );
};

export default EquipmentPermissionList;
